import A1HelpImage1 from "./assets/images/A-1_1.jpg"
import A1HelpImage2 from "./assets/images/A-1_2.jpg"
import A1HelpImage3 from "./assets/images/A-1_3.jpg"
import A2HelpImage1 from "./assets/images/A-2_1.jpg"
import A2HelpImage2 from "./assets/images/A-2_2.jpg"
import A2HelpImage3 from "./assets/images/A-2_3.jpg"
import A3HelpImage1 from "./assets/images/A-3_1.jpg"
import A3HelpImage2 from "./assets/images/A-3_2.jpg"
import A3HelpImage3 from "./assets/images/A-3_3.jpg"
import A4HelpImage1 from "./assets/images/A-4_1.jpg"
import A4HelpImage2 from "./assets/images/A-4_2.jpg"
import A4HelpImage3 from "./assets/images/A-4_3.jpg"
import C1HelpImage1 from "./assets/images/C-1_1.jpg"
import C2HelpImage1 from "./assets/images/C-2_1.jpg"
import C3HelpImage1 from "./assets/images/C-3_1.jpg"
import C4HelpImage1 from "./assets/images/C-4_1.jpg"
import C4HelpImage2 from "./assets/images/C-4_2.jpg"
import C5HelpImage1 from "./assets/images/C-5_1.jpg"
import C6HelpImage1 from "./assets/images/C-6_1.jpg"
import C7HelpImage1 from "./assets/images/C-7_1.jpg"
import C7HelpImage2 from "./assets/images/C-7_2.jpg"
import C8HelpImage1 from "./assets/images/C-8_1.jpg"
import C8HelpImage2 from "./assets/images/C-8_2.jpg"
import C9HelpImage1 from "./assets/images/C-9_1.jpg"
import C9HelpImage2 from "./assets/images/C-9_2.jpg"

export interface KVList<V> {
    key: string
    values: V[]
}

export interface RespondentProfile {
    birthyear: number
    height: number
    weight: number
    leftFootLength: number
    rightFootLength: number
    leftFootBallCircumference: number
    rightFootBallCircumference: number
    leftFootBallWidth: number
    rightFootBallWidth: number
    leftFootTopCircumference: number
    rightFootTopCircumference: number
    leftFootTopHeight: number
    rightFootTopHeight: number
    leftFootSize: string
    rightFootSize: string
    shoesSize: number
    activityFreq: number
    activityRange: number
    topSize: number
    bottomSize: number
}

export interface TestProfile {
    wearStartedAt: number
    wearEndedAt: number
    wearPlace: string
    wearDistance: number
    activityWalk: number
    activityRun: number
    activityMisc: number
    activityMiscText: string
    envAsphalt: number
    envTrack: number
    envRock: number
    envDirt: number
    envForest: number
    envMisc: number
    envMiscText: string
}

export const DEFAULT_TEST_PROFILE: TestProfile = {
    wearStartedAt: Date.now(),
    wearEndedAt: Date.now(),
    wearPlace: "",
    wearDistance: 0,
    activityWalk: 0,
    activityRun: 0,
    activityMisc: 0,
    activityMiscText: "",
    envAsphalt: 0,
    envTrack: 0,
    envRock: 0,
    envDirt: 0,
    envForest: 0,
    envMisc: 0,
    envMiscText: "",
}

export interface User {
    username: string
    firstName: string | null
    lastName: string | null
    tester: boolean
    analyst: boolean
    createdAt: number
    respondentProfile: RespondentProfile
    empnum: string
    is_admin: boolean
}

export interface Questionnaire {
    id: number
    name: string
    brand: string
    modelName: string
    size: string
    guide: string
    season: string
    category: string
    weight: number
    heelHeight: number
    forefootHeight: number
    dropGap: number
    soleImage: string | null
    outerImage: string | null
    innerImage: string | null
    goalDistance: number
    accumulatedDistance: number
    closed: boolean
    hidden: boolean
    submitted: boolean
    submitted_count: number
    tags: string[]
    createdAt: number
    updatedAt: number
    dynamic_data: DynamicSection[]
    type: number
    typeName: string
}

export interface QuestionnaireType {
    id: number
    typeName: string
    typeImage: string
    count: number
}

export interface Filter {
    key: string
    values: Record<string, any>
}

export interface Submission {
    questionnaireId: number
    username: string
    respondentProfile: RespondentProfile
    testProfile: TestProfile
    payload: Record<string, any>
    uploads: Record<string, { url: string; file: File }[]>
    proofImages: { key: number; url: string }[]
    usageImages: { key: number; url: string }[]
}

export interface SubmissionRow {
    questionnaireId: string
    submissionId: string
    username: string
    createdAt: number
    updatedAt: number
    category: string
    key: string
    value: string
    text: string
    like: string
}

export interface DynamicSection {
    id: number
    section: string
    name: string
    description: string | null
    data: DynamicItem[]
}

export interface DynamicItem {
    dynamic_id: number
    helpImages: string
    helpText: string
    max_text: string
    min_text: string
    question: string
    defaultValue: Record<string, any>
}

export interface Definition {
    sections: DefinitionSection[]
}

export interface DefinitionSection {
    key: string
    name: string
    description: string | null
    items: (DefinitionItemLikert | DefinitionItemMarkOnImages | DefinitionItemUploads)[]
}

export interface DefinitionItem {
    key: string
    type: "likert" | "markOnImages" | "uploads"
    question: string
    defaultValue: Record<string, any>
}

export interface DefinitionItemLikert extends DefinitionItem {
    form: "short" | "long"
    labels: string[]
    questionHelp: DefinitionItemHelp
    defaultValue: DefinitionItemLikertValue
}

export interface DefinitionItemLikertValue {
    scale: number
    text: string
}

export interface DefinitionItemHelp {
    helpImages: string[] | null
    helpText: string
    // evaluation: string
    // answerExample: string
}

export interface DefinitionItemMarkOnImages extends DefinitionItem {
    images: {
        key: string
        label: string
    }[]
    defaultValue: Record<string, DefinitionItemMarker[]>
}

export interface DefinitionItemMarker {
    x: number
    y: number
    text: string
    like: boolean
}

export interface DefinitionItemUploads extends DefinitionItem {
    maxImages: number
}

export const QUESTIONNAIRE_DEF: Definition = {
    sections: [
        {
            key: "A",
            name: "A. 신발 피팅 평가",
            description:
                "신발 착용 후 느낀 각 항목에 대한 점수를 '화살표' 위에 <em>명확히</em> 표시하고, 간단한 이유를 서술해 주십시오.",
            items: [
                {
                    key: "1",
                    type: "likert",
                    question: "A-1. 신발의 길이(length)",
                    form: "long",
                    labels: ["길다", "짧다"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [A1HelpImage1, A1HelpImage2, A1HelpImage3],
                        helpText: "발 뒤꿈치부터 발가락 앞 부분까지의 피팅감",
                        // evaluation:
                        //     "발 뒤꿈치를 신발 뒤에 밀착시켜 고정한 뒤 신발 앞부분 공간에 발가락 끝이 여유있는지 확인",
                        // answerExample:
                        //     "뒤꿈치를 밀착해서 신었음에도 불구하고 두번째 발가락 끝이 신발 끝에 닿는 느낌",
                    },
                },
                {
                    key: "2",
                    type: "likert",
                    question: "A-2. 발가락 영역(Toe box)",
                    form: "long",
                    labels: ["매우 느슨", "매우 조임"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [A2HelpImage1, A2HelpImage2, A2HelpImage3],
                        helpText: "신발 앞부분의 발가락을 포함한 발볼 영역 공간에 대한 피팅감",
                        // evaluation:
                        //     "발 뒤꿈치를 신발 뒤에 밀착시켜 고정한 뒤 신발 앞부분 공간에 발가락 및 발볼의 높이와 너비에 여유가 있는지 확인",
                        // answerExample:
                        //     "뒤꿈치를 밀착해서 신었을 때 발가락 윗부분 공간이 매우 타이트하게 느껴지고 발가락이 오므려지는 느낌",
                    },
                },
                {
                    key: "3",
                    type: "likert",
                    question: "A-3. 발등 영역(Instep area)",
                    form: "long",
                    labels: ["매우 느슨", "매우 조임"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [A3HelpImage1, A3HelpImage2, A3HelpImage3],
                        helpText: "신끈이 위치하는 발등 영역에 대한 피팅감",
                        // evaluation:
                        //     "신끈을 조이기 전 신고 벗을 때 착용하기 편한지 확인하고 신발을 신었을 때 발등부분의 조임 정도를 확인",
                        // answerExample:
                        //     "신발을 신고 벗을 때 압박감이 느껴지지 않았으며 신끈을 조이지 않은 상태에서도 적절하게 발을 잡아주는 느낌",
                    },
                },
                {
                    key: "4",
                    type: "likert",
                    question: "A-4. 발 뒤꿈치 영역(Heel area)",
                    form: "long",
                    labels: ["매우 느슨함", "매우 조임"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [A4HelpImage1, A4HelpImage2, A4HelpImage3],
                        helpText: "신발 뒷부분에 발 뒤꿈치가 편안하게 안착되어 있는지 평가",
                        // evaluation:
                        //     "신발을 완전히 착용한 뒤 발 뒤꿈치 공간에 여유가 있는지 확인하고 평소보다 빠른 속도로 걸을 때 발 뒤꿈치가 신발 내부에서 들썩거리는지 점검",
                        // answerExample:
                        //     "신발을 신었을 때 발 뒤꿈치 좌우 공간에 여유가 느껴졌으며 빠른 속도로 걸을 때 발 뒤꿈치가 들썩 거리는 느낌이 반복적으로 발생함",
                    },
                },
            ],
        },
        {
            key: "B",
            name: "B. 통증 부위 표시",
            description: null,
            items: [
                {
                    key: "1",
                    type: "markOnImages",
                    question:
                        "B-1. 피팅 테스트 시 압박 또는 통증이 느껴지는 부분에 표시해주세요. (없을 경우 미표기)",
                    images: [
                        {
                            key: "soleImage",
                            label: "바닥면",
                        },
                        {
                            key: "outerImage",
                            label: "외측면",
                        },
                        {
                            key: "innerImage",
                            label: "내측면",
                        },
                    ],
                    defaultValue: { soleImage: [], outerImage: [], innerImage: [] },
                },
            ],
        },
        {
            key: "C",
            name: "C. 신발 기능성 평가",
            description: null,
            items: [
                {
                    key: "1",
                    type: "likert",
                    question: "C-1. 발 앞꿈치 쿠셔닝(Cushioning)",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [C1HelpImage1],
                        helpText: "신발 앞꿈치 부분의 쿠셔닝 정도를 평가",
                        // evaluation:
                        //     "신발 앞꿈치 부분을 수차례 누르거나 굽혀 봤을 때 눌러지는 느낌이 말랑한지 딱딱한지 확인",
                        // answerExample:
                        //     "발 앞꿈치 부분을 가볍게 눌렀을 때는 단단하게 느껴졌으나 굽히거나 강하게 누를 때는 말랑말랑한 쿠션감이 느껴짐",
                    },
                },
                {
                    key: "2",
                    type: "likert",
                    question: "C-2. 발 뒤꿈치 쿠셔닝(Cushioning)",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [C2HelpImage1],
                        helpText: "신발 뒤꿈치 부분의 쿠셔닝 정도를 평가",
                        // evaluation:
                        //     "신발 뒤꿈치 부분에 체중을 실어 수차례 눌러봤을 때 느낌이 말랑한지 딱딱한지 확인",
                        // answerExample:
                        //     "발 뒤꿈치에 체중을 실어 수차례 눌러봤을 때 신발의 바닥면이 푹푹 꺼지는 듯한 느낌이 발생. 산행을 할 때는 그런 느낌이 적었음",
                    },
                },
                {
                    key: "3",
                    type: "likert",
                    question: "C-3. 통기성(Ventilation)",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [C3HelpImage1],
                        helpText:
                            "신발 내부로 바람이 잘 들어오는지, 신발 내부에 땀이 발생하는 정도를 평가",
                        // evaluation:
                        //     "신발을 신고 평소보다 빠른 걸음으로 30분 남짓 걸었을 때 신발 내부에 열이 발생하는 정도를 확인하여 설문에 응답",
                        // answerExample:
                        //     "30분 남짓 걸은 시점에서 평소보다 신발 내부에 열과 땀이 덜 발생함",
                    },
                },
                {
                    key: "4",
                    type: "likert",
                    question: "C-4. 경량성(Lightness)",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [C4HelpImage1, C4HelpImage2],
                        helpText: "신발을 신고 활동 할 때 무게감에 대한 평가",
                        // evaluation:
                        //     "신발을 착용 후 평소보다 빠른 걸음으로 걷거나 신발을 착용한 발을 들어 올렸을 때 체감되는 무게감을 확인",
                        // answerExample:
                        //     "다리를 들어올렸을 때 평상시 신는 신발보다 살짝 무겁게 느껴짐",
                    },
                },
                {
                    key: "5",
                    type: "likert",
                    question: "C-5. 유연성(Flexibility)",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [C5HelpImage1],
                        helpText:
                            "신발의 앞부분이 잘 굽혀지는지, 굽히는데 힘이 많이 들어가는지 평가",
                        // evaluation:
                        //     "신발을 착용 후 걷거나 발 뒤꿈치를 드는 동작을 할 때 부드럽게 발이 굽혀지는지 확인",
                        // answerExample:
                        //     "발 뒤꿈치를 들 때 앞부분이 부드럽게 굽혀지고 걸을 때도 어색한 느낌없이 부드럽게 굽힘 동작이 발생함",
                    },
                },
                {
                    key: "6",
                    type: "likert",
                    question: "C-6. 안정성(Stability)",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [C6HelpImage1],
                        helpText: "활동을 할 때 신발 밑창이 좌,우로 흔들리는 정도를 평가",
                        // evaluation:
                        //     "신발을 착용 후 평소보다 빠른 걸음으로 걷거나 한 발로 서 있을 때 균형이 흐트러지는 정도를 확인",
                        // answerExample:
                        //     "신발 밑창이 말랑해서 한발로 서있을 때 균형잡기 어렵고 종아리 근육이 과하게 긴장되는 느낌이 있으며 불규칙한 지면을 걸을 때 좌,우 흔들림이 크게 나타남",
                    },
                },
                {
                    key: "7",
                    type: "likert",
                    question: "C-7. 갑피 편안함(Comfort)",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [C7HelpImage1, C7HelpImage2],
                        helpText: "발을 감싸는 신발 윗 부분이 활동하는데 불편함이 없는지 평가",
                        // evaluation:
                        //     "신끈을 조여 신발을 신고 평소보다 빠른 걸음으로 걸을 때 신발 윗부분에 압박을 주거나 통증이 발생하는 부분이 있는지 확인",
                        // answerExample:
                        //     "신발을 신고 걸을 때 발 앞부분이 굽혀지면서 발등에 통증이 발생함. 신끈을 강하게 조일 경우 발목 굽혀지는 부분에도 불편함 발생",
                    },
                },
                {
                    key: "8",
                    type: "likert",
                    question: "C-8. 밑창 편안함(Comfort)",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [C8HelpImage1, C8HelpImage2],
                        helpText: "신발 발바닥 부분에 불편함이 없는지 평가",
                        // evaluation:
                        //     "신발을 신고 평소보다 빠른 걸음으로 걸을 때 신발 바닥면에 반복적인 통증이 있는지 확인",
                        // answerExample:
                        //     "세번째 발가락 바닥면에 단차가 있어 걸을 때 마다 신경 쓰이고 첫번째 엄지발가락쪽 바닥은 오래 걸을 때 통증 발생함",
                    },
                },
                {
                    key: "9",
                    type: "likert",
                    question: "C-9. 접지력(Outsole-Grip)",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: [C9HelpImage1, C9HelpImage2],
                        helpText:
                            "활동 할 때 미끄러짐이 발생하는지, 경사면을 오르고 내리는데 불편함은 없는지 평가",
                        // evaluation:
                        //     "경사면을 오르거나 내릴 때 신발 바닥면의 미끄러짐 현상이 발생하는지 또는 과한 힘이 발생하는지 확인",
                        // answerExample:
                        //     "암릉 지역을 지날 때 높은 경사면의 바위를 오르거나 내려갈 때 미끄러짐 현상 없이 통과할 수 있었음",
                    },
                },
            ],
        },
        {
            key: "D",
            name: "D. 종합적 평가",
            description: null,
            items: [
                {
                    key: "1",
                    type: "likert",
                    question: "D-1. 전체적인 신발의 만족도에 대한 점수와 이유를 기술해 주십시오.",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: null,
                        helpText: "피팅과 기능성을 모두 고려했을 때 신발 구매의향을 점수로 표현",
                        // evaluation:
                        //     "신발을 구매한다고 가정했을 때 위에 응답한 피팅과 기능성에 대한 결과를 종합하여 신발에 대한 만족도 점수와 그 이유에 대해 작성",
                        // answerExample:
                        //     "정비가 잘 된 등산로 또는 하이킹을 목적으로 만든 신발에 맞게 신발이 전체적으로 편안했고 3~4시간 정도 산행에서는 기능성에도 문제가 없다고 판단됨. 발볼영역의 피팅감이 조금 타이트해서 이 부분만 개선되면 피팅감도 더 좋아질 것으로 판단됨",
                    },
                },
                {
                    key: "2",
                    type: "likert",
                    question: "D-2. 신발의 디자인 만족도에 대한 점수와 이유를 기술해 주십시오.",
                    form: "long",
                    labels: ["나쁨", "좋음"],
                    defaultValue: { scale: 0, text: "" },
                    questionHelp: {
                        helpImages: null,
                        helpText: "제품의 외형만을 고려했을 때 신발 구매의향을 점수로 표현",
                        // evaluation:
                        //     "신발의 외관과 컬러 그리고 디테일 등을 육안으로 확인하여 종합적으로 평가",
                        // answerExample:
                        //     "등산화로 출시된 제품이지만 스포티한 느낌에 갑피 소재도 세련되고 가는 선의 그리드 무늬가 고급스러움. 갑피와 중창의 디자인 조화도 알맞고 트렌드에 맞게 맥시멀한 중창 포인트가 매력적임. 외관과 컬러 그리고 디테일 모두 만족스러움",
                    },
                },
            ],
        },
        {
            key: "E",
            name: "E. 이미지 업로드",
            description: null,
            items: [
                {
                    key: "1",
                    type: "uploads",
                    question: "E-1. 활동 인증 스크린샷을 업로드 해주세요 (최대 10개)",
                    maxImages: 10,
                    defaultValue: [],
                    questionHelp: {
                        helpImages: null,
                        helpText: "",
                        // evaluation: "",
                        // answerExample: "",
                    },
                },
                {
                    key: "2",
                    type: "uploads",
                    question:
                        "E-2. 제품의 손상 정도를 보여주는 이미지를 업로드 해주세요 (최대 10개)",
                    maxImages: 10,
                    defaultValue: [],
                    questionHelp: {
                        helpImages: null,
                        helpText: "",
                        // evaluation: "",
                        // answerExample: "",
                    },
                },
            ],
        },
    ],
}

// export const DEFAULT_RESPONSES: Record<string, any> = {}
// QUESTIONNAIRE_DEF.sections.forEach((section) => {
//     section.items.forEach((item) => {
//         const key = `${section.key}:${item.key}`
//         DEFAULT_RESPONSES[key] = item.defaultValue
//     })
// })
