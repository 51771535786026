import logoImg from "../../assets/images/logo.png"
import { selectUser } from "../../features/userSlice"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Slide from "@material-ui/core/Slide"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import MenuIcon from '@material-ui/icons/Menu'
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import React from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        header: {
            height: "3.125rem",
            boxShadow: "0 0 0 rgba(0,0,0,0)",
            "& > div": {
                padding: "0 1.25rem",
                paddingRight: "0",
            },
            "& .MuiToolbar-regular": {
                minHeight: "3.15rem",
            },
            "@media print": {
                display: "none",
            },
        },
        logo: {
            flexGrow: 1,
            paddingTop: ".2rem",
            textAlign: "left",
        },
        logoImg: {
            width: "6rem",
        },
        headerButton: {
            marginTop: "0",
            padding: "0.25rem",
        },
    }),
)

export default function Header(): React.ReactElement {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const classes = useStyles()

    const user = useSelector(selectUser)
    const location = useLocation()

    const trigger = useScrollTrigger({ target: window ? window : undefined })
    const triggerOnTop = useScrollTrigger({ disableHysteresis: true })

    const handleClick = (e: any) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAdmin = () => {
        window.location.href = "/admin/"
    }

    const handleLogout = () => {
        if (Object.keys(JSON.parse(localStorage.getItem("saveQuestionnaire") || "{}")).length !== 0)
            localStorage.removeItem("saveQuestionnaire")

        window.location.href = "/accounts/logout/"
    }

    // const userInfo = (users: any) => {
    //     if (users.empnum) {
    //         return user?.username + " - " + user?.firstName
    //     } else {
    //         return user?.username
    //     }
    // }

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            <AppBar
                id="header"
                position="fixed"
                className={`${classes.header} ${
                    !triggerOnTop && location.pathname.split("/")[1] === "questionnaires"
                        ? "bgtransparent" : "bgwhite"
                }`}
                style={{ background: 'transparent', boxShadow: 'none'}}
            >
                <Toolbar>
                    <Typography component="h1" variant="h6" align="center" className={classes.logo}>
                        <Link to={"/"}>
                            <img
                                src={logoImg}
                                alt="K2 신발연구소 로고"
                                className={classes.logoImg}
                            />
                        </Link>
                    </Typography>
                    <Button
                        className={`${classes.headerButton}`}
                        aria-controls="HeaderMenu"
                        aria-haspopup="true"
                        onClick={handleClick}>
                        <MenuIcon />
                    </Button>
                    <Menu
                        id="HeaderMenu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {/* {user && <MenuItem disabled>{userInfo(user)}</MenuItem>} */}
                        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                        {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                        {
                            (user?.is_admin === true || user?.analyst === true)
                            && <MenuItem onClick={handleAdmin}>관리자 화면</MenuItem>
                        }
                        {user && <MenuItem onClick={handleLogout}>LOGOUT</MenuItem>}
                    </Menu>
                </Toolbar>
            </AppBar>
        </Slide>
    )
}
