import { ReactComponent as DeleteIcon } from '../../assets/images/icon_img_delete.svg'
import itemUpload from "../../assets/images/icon_upload.svg"
import { increase, decrease } from "../../features/loadingSlice"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import imageCompression from "browser-image-compression"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        itemBox: {
            // margin: "2rem 0 4rem",
            padding: "25px 20px",
        },
        itemTitle: {
            lineHeight: "1.4",
            color: "#000",
        },
        itemDesc: {
            paddingLeft: "17px",
            fontSize: "0.875rem",
            fontWeight: 300,
            color: "#888",
        },
        imageList: {
            marginTop: "25px",
            "& td": {
                padding: "0.625rem 0",
                "& img": {
                    maxWidth: "5rem",
                },
                "&:last-child": {
                    width: "2.5rem",
                },
            },
        },
        imageList2: {
            marginTop: "25px",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            columnGap: "10px",
            width: 'calc(100% - 20px)',
        },
        imageItem: {
            position: "relative",
            aspectRatio: '1/1',
            marginBottom: '5px',
            '& img': {
                width: '100%',
                height: '100%',
                borderRadius: 15,
            },
        },
        deleteImageItem: {
            position: "absolute",
            top: 0,
            right: 0,
        },
        fileBox: {
            marginTop: "25px",
        },
        input: {
            display: "none",
        },
        uploadBtn: {
            width: '100%',
            height: 45,
            // marginTop: 20,
            borderRadius: 10,
            backgroundColor: '#F4F4F4',
            '& img': {
                width: 12,
                height: 12,
                marginRight: 7,
                verticalAlign: 'middle',
            }
        },
    }),
)

interface UploadingImage {
    file: File | undefined
    url: string
}

interface UploadedImage {
    key: number
    url: string
}

interface Props {
    payloadKey: string
    question: string
    questionDesc: string
    maxImages: number
    uploadingImages: UploadingImage[]
    uploadedImages: UploadedImage[]
    onChange: (
        payloadKey: string,
        uploadingImages: UploadingImage[],
        uploadedImages: UploadedImage[],
    ) => void
}

export default function ItemUploads(props: Props): React.ReactElement {
    const dispatch = useDispatch()
    const [uploadingImages, setUploadingImages] = useState<UploadingImage[]>([])
    const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>(props.uploadedImages)

    async function compressImage(image: File) {
        dispatch(increase())
        try {
            const options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 1024,
                useWebWorker: true,
            }
            const fileBlob = await imageCompression(image, options)
            const compressFile = new File([fileBlob], fileBlob.name)

            return compressFile
        } catch (error) {
            console.error(error)
        } finally {
            dispatch(decrease())
        }
    }

    async function handleChangeUploadingImages(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.currentTarget?.files?.[0]) return

        const files: UploadingImage[] = await Promise.all(
            Array.from(e.currentTarget.files).map(async (file: File) => {
                const newFile: any = await compressImage(file)
                return {
                    file: newFile,
                    url: URL.createObjectURL(newFile),
                }
            }),
        )
        const newUploadingImages = [...uploadingImages, ...files]

        if (Object.entries(uploadedImages).length + newUploadingImages.length > props.maxImages) {
            alert(`최대${props.maxImages}개 까지 가능합니다.`)
        } else {
            setUploadingImages(newUploadingImages)
            props.onChange(props.payloadKey, newUploadingImages, uploadedImages)
        }
    }

    function handleDeleteUploadedImages(e: React.MouseEvent<HTMLButtonElement>, key: number) {
        const newUploadedImages = uploadedImages.filter((f) => f.key !== key)
        setUploadedImages(newUploadedImages)
        props.onChange(props.payloadKey, uploadingImages, newUploadedImages)
    }

    function handleDeleteUploadingImages(e: React.MouseEvent<HTMLButtonElement>, index: number) {
        const newUploadingImages = uploadingImages.filter((f, idx) => idx !== index)
        setUploadingImages(newUploadingImages)
        props.onChange(props.payloadKey, newUploadingImages, uploadedImages)
    }

    const classes = useStyles()
    return (
        <div className={classes.itemBox}>
            <Typography className={classes.itemTitle}>
                {props.question}
            </Typography>
            <Typography className={classes.itemDesc}>
                {props.questionDesc}
            </Typography>
            {uploadedImages.length > 0
                && <div className={classes.imageList2}>
                    {uploadedImages.map((f, i) => (
                        <div className={`uploaded ${classes.imageItem}`} key={i}>
                            <img src={f.url} alt="" />
                            <IconButton
                                className={classes.deleteImageItem}
                                onClick={(e) => handleDeleteUploadedImages(e, f.key)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>

                    ))}
                </div>
            }
            {uploadingImages.length > 0
                && <div className={classes.imageList2}>
                    {uploadingImages.map((f, i) => (
                        <div className={`uploading ${classes.imageItem}`} key={i}>
                            <img src={f.url} alt="" />
                            <IconButton
                                className={classes.deleteImageItem}
                                onClick={(e) => handleDeleteUploadingImages(e, i)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    ))}
                </div>
            }
            {uploadingImages.length + Object.entries(uploadedImages).length < props.maxImages && (
                <div className={classes.fileBox}>
                    <input
                        accept="image/*"
                        className={classes.input}
                        id={`${props.payloadKey}Upload`}
                        type="file"
                        onChange={handleChangeUploadingImages}
                        multiple
                    />
                    <label htmlFor={`${props.payloadKey}Upload`}>
                        <Button
                            component="span"
                            className={classes.uploadBtn}
                        >
                            <img src={itemUpload} alt="" />
                            이미지 업로드
                        </Button>
                    </label>
                </div>
            )}
        </div>
    )
}
