import LoadingSpinner from "./components/LoadingSpinner"
import { setMessage, selectFlashMessage } from "./features/flashMessageSlice"
import { selectLoading } from "./features/loadingSlice"
import AppRouter from "./router"
import Snackbar from "@material-ui/core/Snackbar"
import React from "react"
import "./App.scss"
import { useSelector, useDispatch } from "react-redux"

export default function App(): React.ReactElement {
    const dispatch = useDispatch()
    const loading = useSelector(selectLoading)
    const flashMessage = useSelector(selectFlashMessage)

    return (
        <>
            <div className="wrapper">
                <AppRouter />
                {flashMessage && (
                    <Snackbar
                        open={flashMessage !== null}
                        onClose={() => dispatch(setMessage(null))}
                        message={flashMessage}
                        autoHideDuration={2000}
                    />
                )}
            </div>
            {loading && <LoadingSpinner />}
        </>
    )
}
