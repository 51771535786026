import ConfirmDialog from "./ConfirmDialog"
import ItemLikert from "./items/ItemLikert"
import ItemMarkOnImages from "./items/ItemMarkOnImages"
import ItemUploads from "./items/ItemUploads"
import QuestionnaireInfo from "./QuestionnaireInfo"
import RespondentProfile from "./RespondentProfile"
import K2Stepper from './Stepper'
import TestProfile from "./TestProfile"
// import { setMessage } from "../features/flashMessageSlice"
import {
    User,
    Questionnaire as QuestionnaireData,
    Submission,
    DynamicItem,
    DynamicSection,
    RespondentProfile as RespondentProfileData,
    TestProfile as TestProfileData,
} from "../types"
import Button from "@material-ui/core/Button"
import { Theme, withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import React from "react"
// import { connect } from "react-redux"
// import { Dispatch } from "redux"

const useStyles = (_: Theme) => ({
    questionnaire: {
        position: "relative",
        overflowY: 'scroll',
        height: '100vh',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        // overflow: "hidden",
        // backgroundColor: "#f1f1f1",
        // borderRadius: "0.25rem",
        // "&.closed::after": {
        //     position: "absolute",
        //     top: "0",
        //     left: "0",
        //     width: "100%",
        //     height: "100%",
        //     content: "''",
        // },
    },

    subHeader: {
        position: "absolute",
        top: "14px",
        left: "10px",
        display: "flex",
        height: '24px',
        zIndex: 20,
    },

    titleContents: {
        padding: "66px 20px 20px",
    },

    stepper: {
        padding: "0 20px",
    },

    stepperMargin: {
        padding: "66px 20px 15px",
    },

    innerScroll: {
        position: 'fixed',
        left: 0, right: 0,
        bottom: 0,
        paddingBottom: 100,
        // padding: '20px 0',
        borderRadius: '30px 30px 0 0',
        backgroundColor: "#fff",
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        inset: 0,
        overscrollBehavior: 'contain',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '&:before': {
            content: "''",
            position: "fixed",
            left: 0, right: 0,
            width: "100%", height: '22px',
            borderRadius: '30px 30px 0 0',
            backgroundColor: "#fff",
            zIndex: 12,
        },
        '&:after': {
            content: "''",
            position: "fixed",
            bottom: 0,
            left: 0, right: 0,
            width: "100%", height: '78px',
            backgroundColor: "#fff",
            zIndex: 12,
        },
    },

    innerScroll1: {
        top: '212px',
        '&:before': {
            top: '212px',
        }
    },

    innerScroll2: {
        top: '120px',
    },

    // sectionname: {
    //     margin: "16px 20px",
    //     fontWeight: 600,
    //     marginBottom: 100,
    // },

    section: {
        margin: "12px 20px",
    },

    questionDesc: {
        marginTop: "0.3125rem",
        color: "#777",
        "& em": {
            color: "#000",
            fontStyle: "normal",
            textDecoration: "underline",
        },
    },

    buttonArea: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        margin: "25px 20px",
        zIndex: 12,
    },

    buttonArea2: {
        display: "grid",
        flexWrap: "wrap",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "10px",
    },

    savebutton: {
        position: "fixed",
        bottom: "6rem",
        right: "1rem",
        zIndex: "10",
    },

    confirmBtn: {
        width: '100%',
        height: 55,
        borderRadius: 10,
        backgroundColor: '#EF6156',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#fff',
        '&:hover': {
            backgroundColor: '#EF6156',
        }
    },

    cancelBtn: {
        backgroundColor: '#F4F4F4',
        color: '#999',
        '&:hover': {
            backgroundColor: '#F4F4F4',
        }
    },

    submitted: {
        color: '#fff',
        position: 'absolute',
        top: '-22px',
        right: 'calc(50% - 69px)',
        transform: 'translateX(50%)',
        fontWeight: 'normal',
        zIndex: "11",
    },

    bubble: {
        display: 'inline-block',
        position: 'relative',
        margin: '0 auto 10px',
        borderRadius: '8px',
        padding: '.25rem .75rem',
        background: '#4FA9BA',
        lineHeight: '1.5',
        "&:after": {
            content: "''",
            position: 'absolute',
            left: '15px',
            bottom: '-8px',
            borderTop: '8px solid #4FA9BA',
            borderRight: '6px solid transparent',
            borderLeft: '6px solid transparent',
        }
    },
})

type Props = {
    user: User
    questionnaire: QuestionnaireData
    submission: Submission
    focusedItem: string | null
    onSubmit: (submission: Submission) => void
    classes: Record<any, any>
    onSave: () => void
}

type State = {
    step: number | null
    stepMax: number
    stepTitleArr: string[]
    submission: Submission
    confirmDialog: boolean
}

class QuestionnaireFormTestComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const stepTitleArr = [
            '응답자 프로파일 확인',
            // '신발 피팅 평가',
            '이미지 업로드',
            '테스트 프로파일',
        ]
        this.state = {
            step: null,
            stepMax: 0,
            stepTitleArr,
            submission: props.submission,
            confirmDialog: false
        }

        this.handlePayloadChange = this.handlePayloadChange.bind(this)
        this.handleSaveCheck = this.handleSaveCheck.bind(this)
        this.handleConfirm = this.handleConfirm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleUploadChange = this.handleUploadChange.bind(this)
        this.handleRespondentProfileChange = this.handleRespondentProfileChange.bind(this)
        this.handleTestProfileChange = this.handleTestProfileChange.bind(this)
    }

    componentDidMount() {
        const { questionnaire } = this.props

        if (questionnaire) {
            const tempArr = questionnaire.dynamic_data.map(data => data.name)

            const stepTitleArr = questionnaire.typeName === "신발" ? [
                '응답자 프로파일 확인',
                // '신발 피팅 평가',
                '이미지 업로드',
                '테스트 프로파일',
            ] : [
                '응답자 프로파일 확인',
                '이미지 업로드',
                '테스트 프로파일',
            ]

            stepTitleArr.splice(1, 0, ...tempArr)
            console.log('arr', stepTitleArr)


            this.setState({
                stepTitleArr,
                stepMax: stepTitleArr.length
            })
        }
    }

    componentWillUnmount() {
        document.body?.classList.remove("noscroll")
    }

private setStep(actionType: string): void {
    let step = this.state.step

    if (actionType === 'next') {
        if (!step && step !== 0) {
        step = 0
        } else if (step !== null) {
        step++
        }
    } else if (actionType === 'prev' && step !== null) {
        step--
    }

    if (step === this.state.stepMax) {
        step--
        this.handleSubmit()
    } else if (step === -1) {
        step = null
    }

    document.body?.classList.toggle('noscroll', step !== null)

    this.setState({ step })
}

    private handleSaveCheck(): void {
        const savedQuestionnaire = JSON.parse(localStorage.getItem("saveQuestionnaire") || "{}")
        if (
            localStorage.getItem("saveQuestionnaire") &&
            this.props.questionnaire.id !== savedQuestionnaire.questionnaireId
        ) {
            this.setState({
                confirmDialog: true,
            })
        } else {
            this.props.onSave()
            localStorage.setItem("saveQuestionnaire", JSON.stringify(this.state.submission))
        }
    }

    private handleConfirm(): void {
        this.props.onSave()
        localStorage.setItem("saveQuestionnaire", JSON.stringify(this.state.submission))

        this.setState({
            confirmDialog: false,
        })
    }

    private handleSubmit(): void {
        if (Object.keys(JSON.parse(localStorage.getItem("saveQuestionnaire") || "{}")).length !== 0)
            localStorage.removeItem("saveQuestionnaire")
        this.props.onSubmit({ ...this.state.submission })
    }

    private handlePayloadChange(key: string, value: any) {
        const newPayload = { ...this.state.submission.payload, [key]: value }
        this.setState({
            ...this.state,
            submission: {
                ...this.state.submission,
                payload: newPayload,
            },
        })
    }

    private handleUploadChange(key: string, uploadingImages: any, uploadedImages: any): void {
        let newProofImages = this.state.submission.proofImages
        let newUsageImages = this.state.submission.usageImages
        if (key === "E:1") {
            newProofImages = uploadedImages
        } else {
            newUsageImages = uploadedImages
        }
        const newUploads = {
            ...this.state.submission.uploads,
            [key]: uploadingImages,
        }
        this.setState({
            ...this.state,
            submission: {
                ...this.state.submission,
                proofImages: newProofImages,
                usageImages: newUsageImages,
                uploads: newUploads,
            },
        })
    }

    private handleRespondentProfileChange(profile: RespondentProfileData): void {
        this.setState({
            ...this.state,
            submission: { ...this.state.submission, respondentProfile: profile },
        })
    }

    private handleTestProfileChange(profile: TestProfileData): void {
        this.setState({
            ...this.state,
            submission: {
                ...this.state.submission,
                testProfile: profile,
            },
        })
    }

    private getSubmitButtonLabel(q: QuestionnaireData): string {
        if (q.closed) return "마감된 설문입니다"
        if (q.submitted) return "수정"
        return "제출"
    }

    private renderSection(
        id: string,
        section: DynamicSection,
        questionnaire: QuestionnaireData,
    ): React.ReactElement {
        const { focusedItem, classes } = this.props
        const { payload } = this.state.submission
        console.log('payload', payload)
        const sectionLength = section.data.length

        return (
            <div key={id}>
                {/* <Typography className={classes.sectionname}>
                    {section.name}
                    <span style={{ color: '#EF6156' }}> *</span>
                </Typography> */}
                {section.description && (
                    <Typography className={classes.questionDesc} gutterBottom style={{ padding: '0 20px'}}>
                        <span dangerouslySetInnerHTML={{ __html: section.description }} />
                    </Typography>
                )}
                {section.data.map((item, num) => {
                    const key = `${section.section}:${num + 1}`
                    const i = item as DynamicItem
                    const fitting = section.name.includes('피팅')
                    if (section.section !== "B") {
                        console.log('section', section)
                        return <div key={key}>
                            <ItemLikert
                                payloadKey={key}
                                question={i.question}
                                form={"long"}
                                labels={[i.min_text, i.max_text]}
                                questionHelp={{
                                    helpImages: [i.helpImages],
                                    helpText: i.helpText,
                                }}
                                focused={key === focusedItem}
                                value={payload[key]}
                                onChange={this.handlePayloadChange}
                                fitting={fitting}
                            />
                            {
                                sectionLength - 1 !== num && <div className='divider'></div>
                            }
                        </div>
                    } else {
                        const images = [
                            {
                                key: "outerImage",
                                label: "이미지1",
                            },
                            {
                                key: "innerImage",
                                label: "이미지2",
                            },
                            {
                                key: "soleImage",
                                label: "이미지3",
                            },
                        ]
                        return <div className={classes.section} key={images[num].key}>
                            <ItemMarkOnImages
                                key={num}
                                payloadKey={key}
                                questionnaire={questionnaire}
                                question={i.question}
                                images={images}
                                value={payload[key]}
                                onChange={this.handlePayloadChange}
                            />
                        </div>
                    }
                })}
            </div>
        )
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.state.step !== prevState.step) {
            document.getElementById('scroller')?.scrollTo(0, 0)
        }
    }

    render(): React.ReactElement {
        const { questionnaire, classes, user } = this.props
        const {
            respondentProfile,
            testProfile,
            uploads,
            proofImages,
            usageImages,
        } = this.state.submission
        const { step, stepMax, stepTitleArr, confirmDialog } = this.state
        const userInfo = (users: any) => {
            if (users.empnum) {
                return users.username + " - " + users.firstName
            } else {
                return users.username
            }
        }

        let stepContentCnt = 0

        return (
            <div className={`${classes.questionnaire} ${questionnaire.closed ? "closed" : ''}`}>
                <div className={classes.header}>
                    <div>
                        <Typography className={`${classes.subHeader}`} onClick={() => {
                            console.log('history back')
                            window.history.back()
                            }}>
                            <ChevronLeftIcon />
                            {step === null ? '설문상세' : stepTitleArr[step]}
                        </Typography>
                    </div>
                    {
                        step === 0 
                        && <div className={classes.titleContents}>
                            <Typography variant='h6'>{user && userInfo(user)} 님,</Typography>
                            <Typography variant='body2'>최근에 입력한 프로파일을 확인해주세요.</Typography>
                            <Typography variant='body2'>이전과 동일한 경우 다음을 눌러주세요.</Typography>
                        </div>
                    }
                    {
                        (step === 0 || step) &&
                            <div className={`${classes.stepper} ${step !== 0 ? classes.stepperMargin : ''}`}>
                                <K2Stepper step={step} stepMax={stepMax} />
                            </div>
                    }
                </div>
                {step === null && <QuestionnaireInfo questionnaire={questionnaire} className={`stepContent`} />}
                {
                    step === 0 && questionnaire.typeName === '신발' && (
                        <div className={`${classes.innerScroll} ${classes.innerScroll1}`}>
                            <div className={`stepContent`}>
                                {respondentProfile && (
                                    <RespondentProfile
                                        profile={respondentProfile}
                                        onChange={this.handleRespondentProfileChange}
                                    />
                                )}
                            </div>
                        </div>
                    )
                }
                {/* {
                    step === 0 && questionnaire.typeName === "신발" && (
                        <div className={`${classes.innerScroll} ${classes.innerScroll1}`}>
                            <div className={`stepContent`}>
                                {respondentProfile && (
                                    <RespondentProfile
                                        profile={respondentProfile}
                                        onChange={this.handleRespondentProfileChange}
                                    />
                                )}
                            </div>
                        </div>
                    )
                } */}
                {
                    step === 0 && questionnaire.typeName !== "신발" && 
                    (
                        <div className={`${classes.innerScroll} ${classes.innerScroll1}`}>
                            <div className={`stepContent`}>
                                {respondentProfile && (
                                    <RespondentProfile
                                        profile={respondentProfile}
                                        onChange={this.handleRespondentProfileChange}
                                    />
                                )}
                            </div>
                        </div>
                    )
                    // :  step !== 0 && questionnaire.typeName !== '신발' && (
                    //     <div id='scroller' className={`${classes.innerScroll} ${classes.innerScroll2}`}>
                    //         {questionnaire.dynamic_data.map((section, idx) => {
                    //             stepContentCnt++
                    //             return <div className={`stepContent`} key={section.id}>
                    //                 {(step === 0 || step) && step === idx && this.renderSection((section.id).toString(), section, questionnaire)}
                    //             </div>
                    //         })}
                    //         {
                    //             step === stepContentCnt
                    //             && <div className={`stepContent stepContent${stepContentCnt}`}>
                    //                 <ItemUploads
                    //                     payloadKey={"E:1"}
                    //                     question={"1. 활동 인증 스크린샷을 업로드 해주세요"}
                    //                     questionDesc={"(최대 10개 / 30MB까지 첨부가능)"}
                    //                     maxImages={10}
                    //                     uploadingImages={uploads["E:1"]}
                    //                     uploadedImages={proofImages}
                    //                     onChange={this.handleUploadChange}
                    //                 />
                    //                 <div className='divider'></div>
                    //                 <ItemUploads
                    //                     payloadKey={"E:2"}
                    //                     question={"2. 제품의 손상 정도를 보여주는 이미지를 업로드 해주세요"}
                    //                     questionDesc={"(최대 10개 / 30MB까지 첨부가능)"}
                    //                     maxImages={10}
                    //                     uploadingImages={uploads["E:2"]}
                    //                     uploadedImages={usageImages}
                    //                     onChange={this.handleUploadChange}
                    //                 />
                    //             </div>
                    //         }
                    //     </div>
                    // )
                }
                {
                    // (!!step && (step !== 0) && questionnaire.typeName === "신발") && (
                    (!!step && (step !== 0) ) && (
                        <div id='scroller' className={`${classes.innerScroll} ${classes.innerScroll2}`}>
                            {questionnaire.dynamic_data.map((section, idx) => {
                                stepContentCnt++
                                return <div className={`stepContent`} key={section.id}>
                                    {(step && step === idx + 1) && this.renderSection((section.id).toString(), section, questionnaire)}
                                </div>
                            })}
                            {
                                step === stepContentCnt + 1
                                && <div className={`stepContent stepContent${stepContentCnt}`}>
                                    <ItemUploads
                                        payloadKey={"E:1"}
                                        question={"1. 활동 인증 스크린샷을 업로드 해주세요"}
                                        questionDesc={"(최대 10개 / 30MB까지 첨부가능)"}
                                        maxImages={10}
                                        uploadingImages={uploads["E:1"]}
                                        uploadedImages={proofImages}
                                        onChange={this.handleUploadChange}
                                    />
                                    <div className='divider'></div>
                                    <ItemUploads
                                        payloadKey={"E:2"}
                                        question={"2. 제품의 손상 정도를 보여주는 이미지를 업로드 해주세요"}
                                        questionDesc={"(최대 10개 / 30MB까지 첨부가능)"}
                                        maxImages={10}
                                        uploadingImages={uploads["E:2"]}
                                        uploadedImages={usageImages}
                                        onChange={this.handleUploadChange}
                                    />
                                </div>
                            }
                            {step === stepContentCnt + 2 && (
                                <div className={`stepContent stepContent${stepContentCnt}`}>
                                    <TestProfile profile={testProfile} onChange={this.handleTestProfileChange} />
                                </div>
                            )}
                        </div>
                    )
                }
                <div className={`${classes.buttonArea} ${step || step === 0 ? classes.buttonArea2 : ''}`}>
                    {!questionnaire.submitted && (
                        <Button
                            className={classes.savebutton}
                            variant="contained"
                            size="large"
                            disabled={questionnaire.closed}
                            onClick={this.handleSaveCheck}
                        >
                            임시저장
                        </Button>
                    )}
                    {step || step === 0 ?
                        <>
                            <Button type="button" className={`${classes.cancelBtn} ${classes.confirmBtn}`} onClick={() => this.setStep('prev')}>
                                이전
                            </Button>
                            <Button type="button" className={`${classes.confirmBtn}`} onClick={() => {
                                    // this.handleSubmit()
                                    this.setStep('next')
                                }}>
                                다음
                            </Button>
                        </> : <>
                            <Button type="button" className={`${classes.confirmBtn} ${questionnaire.closed ? classes.cancelBtn: ''}`} onClick={() => {
                                if (questionnaire.closed) {
                                    alert('이 설문은 이미 마감되어 설문할 수 없습니다. 다른 설문을 시작해 주세요.')
                                } else {
                                    this.setStep('next')
                                }
                            }}>
                                <div className={classes.submitted}>
                                    <div className={classes.bubble}>
                                        {`${questionnaire.submitted_count}명이 설문했어요!`}
                                    </div>
                                </div>
                                설문 시작하기
                            </Button>
                        </>
                    }
                    {/* <Button
                        className={classes.confirmBtn}
                        variant="contained"
                        // color="primary"
                        // fullWidth={true}
                        // size="large"
                        disabled={questionnaire.closed}
                        onClick={this.handleSubmit}
                    >
                        {this.getSubmitButtonLabel(questionnaire)}
                    </Button> */}
                </div>
                {confirmDialog && (
                    <ConfirmDialog
                        description="임시저장한 다른 내역이 있습니다. 삭제하고 덮어씌우시겠습니까?"
                        onClose={() =>
                            this.setState({
                                confirmDialog: false,
                            })
                        }
                        onConfirm={this.handleConfirm}
                    />
                )}
            </div>
        )
    }
}

// const mapDispatchToProps = (dispatch: Dispatch) => ({
//     onSave: () => dispatch(setMessage("임시저장이 완료되었습니다.")),
// })

// export default withStyles(useStyles as any)(
//     connect(null, mapDispatchToProps)(QuestionnaireFormTestComponent),
// )

export default withStyles(useStyles as any)(QuestionnaireFormTestComponent)
