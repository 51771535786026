// import { K2StepIcon } from './QuestionnaireStepper'
// import K2Stepper from './Stepper'
import ProgressDistance from "./ProgressDistance"
import shoesDropGap from "../assets/images/shoes_dropgap.svg"
import shoeforefootHeight from "../assets/images/shoes_forefootheight.svg"
import shoeheelHeight from "../assets/images/shoes_heelheight.svg"
import shoesWeight from "../assets/images/shoes_weight.svg"
import defaultShoesImage1 from "../assets/images/test_img1.png"
import defaultShoesImage2 from "../assets/images/test_img2.png"
import defaultShoesImage3 from "../assets/images/test_img3.png"
import { selectUser } from "../features/userSlice"
import { Questionnaire as QuestionnaireData } from "../types"
import Box from "@material-ui/core/Box"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Button from "@material-ui/core/Button"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CreateIcon from "@material-ui/icons/Create"
import React from "react"
import { useSelector } from "react-redux"

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        infoimage: {
            display: "flex",
            position: "relative",
            paddingBottom: "60px",
            justifyContent: "center",
            // alignItems: "center",
            paddingTop: "60px",
            textAlign: "center",
            // backgroundColor: "#F5F5F5",
            // borderRadius: "0 0 1.375rem 1.375rem",
            "@media (min-width: 600px)": {
                height: "25rem",
            },
            "& img": {
                maxWidth: "70%",
                objectFit: "contain",
            },
        },

        editButtons: {
            position: "absolute",
            right: "1rem",
            bottom: "1rem",
        },

        infoBox: {
            marginBottom: 100,
            borderRadius: '30px 30px 0 0',
            backgroundColor: "#fff",
        },

        infotitle: {
            padding: 20,
            fontWeight: 500,
            "& .MuiBreadcrumbs-separator": {
                margin: "-1px 0.375rem 0",
                fontSize: "0.625rem",
                color: "#999",
            },
            "& p": {
                marginBottom: "2px",
                fontSize: "0.875rem",
                color: "#888",
            },
            "& .titleInfo": {
                color: '#999999',
                fontSize: 14
            }
        },

        progressbar: {
            padding: 20,
            paddingBottom: '1.625rem',
        },

        featureWrapper: {
            display: "grid",
            flexWrap: "wrap",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "6px",
            width: '100%',
            "& .imgContainer": {
                position: "relative",
                display: "inline-block",
                width: "4.06rem",
                height: "4.06rem",
                lineHeight: ".5rem",
            },
            "& .imgContainer img": {
                position: "relative",
                zIndex: 1,
                top: '50%', left: 'calc(50%)',
                transform: 'translate(-50%, -50%)',
                width: "2.88rem",
                height: "2.88rem",
            },
            "& .imgContainer:after": {
                content: "''",
                position: "absolute",
                top: 0, left: 0,
                display: "block",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                backgroundColor: "#F5F5F5",
            },
        },

        features: {
            position: "relative",
            padding: '20px 20px 0',
            zIndex: 8,
        },

        featureTitle: {
            marginBottom: 2,
        },

        feature: {
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            margin: '8px 0',
            fontSize: "1rem",
            color: '#000',
            "& .title": {
                fontSize: ".75rem",
                color: '#888',
            },
        },

        infofocus: {
            marginTop: "1.6rem",
            paddingBottom: "1.875rem",
            fontFamily: "Noto Sans KR",
            fontSize: ".875rem",
        },
    }),
)

interface Props {
    className?: string
    questionnaire?: QuestionnaireData
}

function QuestionnaireInfo({ className, questionnaire }: Props): React.ReactElement {
    const defaultShoesImage = [defaultShoesImage1, defaultShoesImage2, defaultShoesImage3]
    const user = useSelector(selectUser)
    const classes = useStyles()
    // const [guide, setGuide] = useState<string[] | undefined>([])

    console.log('questionnaire info', questionnaire)

    // useEffect(() => {
    //     console.log('guide', questionnaire?.guide)
    //     setGuide(questionnaire?.guide.split('\n\r'))
    // }, [])

    if (!user) return <div />

    return (
        <>
            <Box className={`${className} ${classes.infoimage}`} style={{marginTop: '20px'}}>
                {/* TODO test 코드 제거  */}
                <img
                    src={`https://srl.k2group.co.kr/${questionnaire?.outerImage || defaultShoesImage[questionnaire?.id || 0]}`}
                    // src={questionnaire.outerImage || defaultShoesImage[questionnaire.id]}
                    alt="신발"
                />
                {user.analyst && (
                    <Box className={classes.editButtons}>
                        <Button
                            variant="contained"
                            onClick={() =>
                                (window.location.href = `/admin/main/questionnaire/${questionnaire?.id}/change/`)
                            }
                            size="small"
                            startIcon={<CreateIcon />}
                        >
                            설문지 수정
                        </Button>
                    </Box>
                )}
            </Box>
            <Box className={classes.infoBox}>
                <Typography variant="h6" component="h2" className={classes.infotitle}>
                    <Typography variant="body2" component="p">
                        {questionnaire?.category}
                    </Typography>
                    {/* {questionnaire?.modelName}{" "} */}{questionnaire?.name}
                    <Breadcrumbs separator="|">
                        <Typography variant="body2" component="p" className='titleInfo'>
                            {questionnaire?.season}
                        </Typography>
                        <Typography variant="body2" component="p" className='titleInfo'>
                            {questionnaire?.size === "" ? "" : `${questionnaire?.size}`}
                        </Typography>
                        <Typography variant="body2" component="p" className='titleInfo'>
                            {new Date(questionnaire?.createdAt || '').toISOString().split("T")[0]}
                        </Typography>
                    </Breadcrumbs>
                </Typography>
                {questionnaire?.typeName === "신발" && (
                    <Box className={`${classes.progressbar}`}>
                        <ProgressDistance
                            distance={questionnaire.accumulatedDistance}
                            totalDistance={questionnaire.goalDistance}
                        />
                    </Box>
                )}
                <div className='divider'></div>
                {questionnaire?.typeName === "신발" && (
                    <div className={classes.features} >
                        <Typography className={classes.featureTitle}>제품 특징</Typography>
                        <ul className={classes.featureWrapper}>
                            <li className={classes.feature}>
                                {/* <object data={shoesWeight}type="image/svg+xml"></object> */}
                                <div className={'imgContainer'}>
                                    <img src={shoesWeight} alt="신발 무게" />
                                </div>
                                <div>
                                    <span className={'title'}>무게</span>
                                    <br />
                                    <span>{questionnaire.weight}g</span>
                                </div>
                            </li>
                            <li className={classes.feature}>
                                <div className={'imgContainer'}>
                                    <img src={shoeforefootHeight} alt="신발 앞부분 높이" />
                                </div>
                                <div>
                                    <span className={'title'}>Forefoot 높이</span>
                                    <br />
                                    <span>{questionnaire.forefootHeight}mm</span>
                                </div>
                            </li>
                            <li className={classes.feature}>
                                <div className={'imgContainer'}>
                                    <img src={shoeheelHeight} alt="신발 뒷부분 높이" />
                                </div>
                                <div>
                                    <span className={'title'}>Heel 높이</span>
                                    <br />
                                    <span>{questionnaire.heelHeight}mm</span>
                                </div>
                            </li>
                            <li className={classes.feature}>
                                <div className={'imgContainer'}>
                                    <img src={shoesDropGap} alt="신발 drop 차이" />
                                </div>
                                <div>
                                    <span className={'title'}>Drop 차이</span>
                                    <br />
                                    <span>{questionnaire.heelHeight - questionnaire.forefootHeight}mm</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
                <div className={classes.features}>
                    {/* <div style={{borderTop: '1px dotted #BBBBBB'}}></div> */}
                    <Typography
                        variant="body1"
                        className={classes.infofocus}
                        dangerouslySetInnerHTML={{
                            __html: (questionnaire?.guide || "입력된 설문 가이드가 없습니다.").replace(
                                /\n/g,
                                "<br>\n",
                            ),
                        }}
                    />
                </div>
            </Box>
        </>
    )
}

export default React.memo(QuestionnaireInfo)
