import MarkerDialog from "./MarkerDialog"
import BadIcon from '../assets/images/icon_bad.svg'
import GoodIcon from '../assets/images/icon_good.svg'
import { DefinitionItemMarker } from "../types"
import Box from "@material-ui/core/Box"
import Chip from "@material-ui/core/Chip"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import React, { useRef, useEffect, useState } from "react"

interface Props {
    onChange: (markerList: DefinitionItemMarker[]) => void
    markers: DefinitionItemMarker[]
    imageSrc: string
    label: string
    disabled: boolean
}

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        imgBox: {
            position: "relative",
            fontSize: "0",
            textAlign: "center",
        },
        imgLabel: {
            marginBottom: "1.5rem",
            fontWeight: 500,
        },
        canvasImg: {
            width: "100%",
        },
    }),
)

export default function MarkableImage(props: Props): React.ReactElement {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const canvas = canvasRef.current

    const [imageLoaded, setImageLoaded] = useState(false)
    const [image] = useState(new Image())
    const [markerDialog, setMarkerDialog] = useState(false)
    const [markers, setMarkers] = useState(props.markers)
    const [curMarker, setCurMarker] = useState<DefinitionItemMarker>({ x: 0, y: 0, text: "", like: true })

    useImageUpdateEffect(canvas, props.imageSrc, image, imageLoaded, setImageLoaded)

    const classes = useStyles()

    function updateMarkers(newMarkers: DefinitionItemMarker[]) {
        setMarkers(newMarkers)
        props.onChange(newMarkers)
    }

    function handleImageClick(e: React.MouseEvent<HTMLCanvasElement>) {
        const ctx = canvas?.getContext("2d")
        if (!canvas || !ctx) return

        const { left, top } = e.currentTarget.getBoundingClientRect()
        const x = e.clientX - left
        const y = e.clientY - top
        const pixel = ctx.getImageData(x, y, 1, 1)
        const alpha = pixel.data[3]
        if (alpha === 0) return

        setCurMarker({ x: x / canvas.width, y: y / canvas.height, text: "", like: true })
        props.disabled ? setMarkerDialog(false) : setMarkerDialog(true)
    }

    function handleConfirm(text: string, like: boolean): void {
        if (curMarker.text === "") {
            // New
            const newMarker = {
                x: curMarker.x,
                y: curMarker.y,
                text,
                like: like,
            }
            updateMarkers([...markers, newMarker])
        } else {
            // Edit
            updateMarkers(markers.map((m) => (m === curMarker ? { ...m, text, like } : m)))
        }
        setMarkerDialog(false)
    }

    function handleDelete() {
        updateMarkers(markers.filter((m) => m !== curMarker))
        setMarkerDialog(false)
    }

    function handleMark(marker: DefinitionItemMarker) {
        setCurMarker(marker)
        props.disabled ? setMarkerDialog(false) : setMarkerDialog(true)
    }

    return (
        <Box>
            <Chip label={props.label} className={classes.imgLabel} />
            <Box className={classes.imgBox}>
                <canvas className={classes.canvasImg} ref={canvasRef} onClick={handleImageClick} />
                {markers.map((marker: DefinitionItemMarker, i) => (
                    <Tooltip key={i} title={marker.text}>
                        <img
                            style={{
                                position: "absolute",
                                top: `calc(${marker.y * 100}% - 0.75rem)`,
                                left: `calc(${marker.x * 100}% - 0.75rem)`,
                                width: '36px',
                                height: '36px',
                            }}
                            src={marker.like ? GoodIcon : BadIcon}
                            onClick={() => handleMark(marker)} />
                        {/* <CustomIcon
                            color="secondary"
                            style={{
                                top: `calc(${marker.y * 100}% - 0.75rem)`,
                                left: `calc(${marker.x * 100}% - 0.75rem)`,
                            }}
                            onClick={() => handleMark(marker)}
                        /> */}
                    </Tooltip>
                ))}
            </Box>
            {markerDialog && (
                <MarkerDialog
                    onCancel={() => setMarkerDialog(false)}
                    onConfirm={(text: string, like: boolean) => handleConfirm(text, like)}
                    onDelete={() => handleDelete()}
                    markerText={curMarker.text}
                    markerLike={curMarker.like}
                    src={props.imageSrc}
                />
            )}
        </Box>
    )
}

function useImageUpdateEffect(
    canvas: HTMLCanvasElement | null,
    src: string,
    image: HTMLImageElement,
    loaded: boolean,
    setLoaded: (loaded: boolean) => void,
): void {
    if (!loaded) {
        image.crossOrigin = "Anonymous"
        image.src = src + "?cacheBurster=1"
        image.addEventListener("load", () => setLoaded(true))
    }

    useEffect(() => {
        function updateImage() {
            const ctx = canvas?.getContext("2d")
            if (!canvas || !ctx) return

            canvas.width = canvas.clientWidth
            canvas.height = (canvas.width * image.height) / image.width
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
        }

        updateImage()
        window.addEventListener("resize", updateImage)
        return () => window.removeEventListener("resize", updateImage)
    }, [canvas, image, loaded])
}
