import { submit } from "../features/userSlice"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
// import FormLabel from "@material-ui/core/FormLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { useDispatch } from "react-redux"

// interface Props {
//     onClose: () => void
// }

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        precautions: {
            marginTop: "0.625rem",
            fontSize: "0.75rem",
        },
    }),
)

export default function PermissionDialog(): React.ReactElement {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [permission, setPermission] = React.useState("tester")
    const [open, setOpen] = React.useState(true)

    const handleSubmit = () => {
        dispatch(submit(permission))
        setOpen(false)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPermission((event.target as HTMLInputElement).value)
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
        >
            <DialogTitle>권한을 선택해주세요.</DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    {/* <FormLabel component="legend">권한</FormLabel> */}
                    <RadioGroup
                        aria-label="permission"
                        name="permission1"
                        value={permission}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="tester" control={<Radio />} label="테스터" />
                        <FormControlLabel value="analyst" control={<Radio />} label="분석가" />
                        {/* <FormControlLabel value="admin" control={<Radio />} label="관리자" /> */}
                    </RadioGroup>
                </FormControl>
                <Typography className={classes.precautions}>
                    ※ 한가지 모드만 선택 가능하며, 권한 변경 시 로그아웃 후 다시 선택하세요.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    )
}
