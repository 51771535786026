import DatePicker from "./DatePicker"
import ItemPercentages from "./items/ItemPercentages"
import QuestionnaireTextField from "./QuestionnaireTextField"
import { TestProfile as TestProfileData } from "../types"
import InputAdornment from "@material-ui/core/InputAdornment"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        section: {
            margin: "12px 20px",
            fontWeight: 600,
        },
        sectionname: {
            margin: "16px 20px",
            fontWeight: 600,
        },
        dateArea: {
            display: "grid",
            flexWrap: "wrap",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "10px",
        },
    }),
)

interface Props {
    profile: TestProfileData
    onChange: (value: TestProfileData) => void
}

function TestProfile({ profile, onChange }: Props): React.ReactElement {
    const classes = useStyles()

    return (
        <div style={{paddingTop: '10px'}}>
            <Typography className={`${classes.section}`}>
                1. 테스트 기간/장소/거리
                <span style={{ color: '#EF6156' }}> *</span>
            </Typography>
            <div className={`${classes.section} ${classes.dateArea}`} style={{ marginTop: '16px'}}>
                <DatePicker
                    label={"시작일"}
                    value={profile.wearStartedAt}
                    onChange={(value) => onChange({ ...profile, wearStartedAt: value })}
                />
                <DatePicker
                    label={"종료일"}
                    value={profile.wearEndedAt}
                    onChange={(value) => onChange({ ...profile, wearEndedAt: value })}
                />
            </div>
            <div className={`${classes.section}`} style={{ paddingBottom: '16px'}}>
                <QuestionnaireTextField
                    fullWidth={true}
                    label="활동 장소"
                    InputLabelProps={{ shrink: true }}
                    placeholder="활동 장소를 작성해주세요."
                    value={profile.wearPlace}
                    onChange={(e) => onChange({ ...profile, wearPlace: e.target.value })}
                    required
                />
                <QuestionnaireTextField
                    type="number"
                    fullWidth={true}
                    label="착용 거리"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">km</InputAdornment>,
                        inputProps: { min: 0 },
                    }}
                    placeholder="10"
                    value={profile.wearDistance}
                    onChange={(e) =>
                        onChange({
                            ...profile,
                            wearDistance: e.target.value === "" ? NaN : +e.target.value,
                        })
                    }
                    required
                />
            </div>
            <div className='divider'></div>
            <div className={`${classes.section}`}>
                <ItemPercentages
                    title="2. 테스트 활동"
                    itemList={[
                        { key: "activityWalk", label: "걷기" },
                        { key: "activityRun", label: "달리기" },
                        { key: "activityMisc", label: "기타", textKey: "activityMiscText" },
                    ]}
                    values={{
                        activityWalk: profile.activityWalk,
                        activityRun: profile.activityRun,
                        activityMisc: profile.activityMisc,
                        activityMiscText: profile.activityMiscText,
                    }}
                    onChange={(key, value) => onChange({ ...profile, [key]: value })}
                />
            </div>
            <div className='divider'></div>
            <div className={`${classes.section}`}>
                <ItemPercentages
                    title="3. 테스트 환경"
                    itemList={[
                        { key: "envAsphalt", label: "아스팔트" },
                        { key: "envTrack", label: "트랙" },
                        { key: "envRock", label: "암반" },
                        { key: "envDirt", label: "흙길" },
                        { key: "envForest", label: "숲길" },
                        { key: "envMisc", label: "기타", textKey: "envMiscText" },
                    ]}
                    values={{
                        envAsphalt: profile.envAsphalt,
                        envTrack: profile.envTrack,
                        envRock: profile.envRock,
                        envDirt: profile.envDirt,
                        envForest: profile.envForest,
                        envMisc: profile.envMisc,
                        envMiscText: profile.envMiscText,
                    }}
                    onChange={(key, value) => onChange({ ...profile, [key]: value })}
                />
            </div>
        </div>
    )
}

export default React.memo(TestProfile)
