import Comparison from "../../components/reports/Comparison"

import { Server } from "../../server"
import { Questionnaire, SubmissionRow } from "../../types"
import React from "react"
import useSWR from "swr"

const server = Server.getServer()

type Props = { qids: number[] }

export default function ComparisonPage({ qids }: Props): React.ReactElement {
    const questionnaires = useQuestionnaire(qids)
    const rows = useSubmissionRows(qids)
    return <Comparison questionnaires={questionnaires} rows={rows} />
}

function useQuestionnaire(qids: number[]): Questionnaire[] {
    const result = useSWR(`/api/analysis/questionnaires?qids=${qids.join(",")}`, () =>
        server.getQuestionnairesForAnalysis(qids),
    ) as any
    return (result.data as Questionnaire[]) || []
}

function useSubmissionRows(qids: number[]): SubmissionRow[] {
    const result = useSWR(`/api/analysis/submissions?qids=${qids.join(",")}`, () =>
        server.getSubmissionsForAnalysis(qids),
    ) as any
    return (result.data as SubmissionRow[]) || []
}
