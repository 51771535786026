import DateFnsUtils from "@date-io/date-fns"
import { makeStyles, Theme } from "@material-ui/core/styles"
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
    KeyboardDateTimePickerProps,
} from "@material-ui/pickers"
// import { WrapperVariant } from "@material-ui/pickers/wrappers/Wrapper"
import { ko } from "date-fns/locale"
import React from "react"

interface Props {
    label: string
    value: number
    onChange: (value: number) => void
}

// type StyledCustomDatePickerProps = Omit<CustomDatePickerProps, "classes"> &
// StyledComponentProps<"root">

/**
const CustomDatePicker = withStyles({
    root: {
        margin: 0,
        "& label + .MuiInput-formControl": {
            marginTop: "1.25rem",
            fontSize: "0.875rem",
        },
        "& .MuiInputBase-input": {
            padding: "0.5rem 0",
            height: "1.625rem",
            fontWeight: "500",
            fontSize: "1rem",
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: "#cecfd6",
        },
        "& .MuiInputLabel-shrink": {
            transform: "none",
            fontSize: "0.875rem",
            color: "#000",
            fontWeight: "700",
        },
        "& .Mui-required": {
            color: "#000",
            fontWeight: "700",
        },
    },
})(KeyboardDateTimePicker)
*/

const customStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: 0,
        "& label + .MuiInput-formControl": {
            marginTop: "1.25rem",
            fontSize: "0.875rem",
        },
        "& .MuiInputBase-input": {
            padding: "0.5rem 0",
            height: "1.625rem",
            fontWeight: "500",
            fontSize: "1rem",
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: "#cecfd6",
        },
        "& .MuiInputLabel-shrink": {
            transform: "none",
            fontSize: "0.875rem",
            color: "#000",
            fontWeight: "700",
        },
        "& .Mui-required": {
            color: "#000",
            fontWeight: "700",
        },
    },
}))

const CustomDatePicker: React.FC<KeyboardDateTimePickerProps> = (props) => {
    const classes = customStyles()
    return <KeyboardDateTimePicker {...props} className={classes.root} />
}

export default function DatePicker({ label, value, onChange }: Props): React.ReactElement {
    const handleDateChange = (date: Date | null) => {
        if (date) onChange(+date)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
            <CustomDatePicker
                fullWidth={true}
                variant="inline"
                format="yyyy/MM/dd HH:mm"
                margin="normal"
                label={label}
                autoOk={true}
                ampm={false}
                value={new Date(value)}
                onChange={handleDateChange}
                InputLabelProps={{ shrink: true }}
                invalidDateMessage="날짜가 올바르지 않습니다."
            />
        </MuiPickersUtilsProvider>
    )
}
