import { selectUser } from "../features/userSlice"
import Button from "@material-ui/core/Button"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import WarningIcon from "@material-ui/icons/Warning"
import React from "react"
import { useSelector } from "react-redux"

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        nopage: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 206px)",
            textAlign: "center",
        },
        nopagelogo: {
            position: "relative",
            width: 80,
            height: 80,
            margin: "0 auto",
            borderRadius: "50%",
            backgroundColor: "rgba(209, 19, 28, 0.8)",
            "& svg": {
                position: "absolute",
                top: "50%",
                left: "50%",
                fontSize: 40,
                color: "#fff",
                transform: "translate(-50%, -50%)",
            },
        },
        nopagetext: {
            margin: "1.5rem 0",
            fontSize: "1rem",
            color: "#666",
        },
    }),
)

export default function NoPage(): React.ReactElement {
    const classes = useStyles()
    const user = useSelector(selectUser)

    return (
        <div className={classes.nopage}>
            <div className={classes.nopagelogo}>
                <WarningIcon />
            </div>
            <Typography className={classes.nopagetext}>
                요청하신 페이지를 찾을 수 없습니다.
            </Typography>
            <Button variant="contained" href={!user ? "/accounts/login" : "/info"}>
                {!user ? "로그인 화면으로 돌아가기" : "메인 화면으로 돌아가기"}
            </Button>
        </div>
    )
}
