import ConfirmDialog from "./ConfirmDialog"
import ProgressDistance from "./ProgressDistance"
import QuestionnaireUtil from "./QuestionnaireUtil"
import defaultShoesImage1 from "../assets/images/test_img1.png"
import defaultShoesImage2 from "../assets/images/test_img2.png"
import defaultShoesImage3 from "../assets/images/test_img3.png"
import { selectUser } from "../features/userSlice"
import { Server } from "../server"
import { QuestionnaireType, Questionnaire, KVList } from "../types"
import Box from "@material-ui/core/Box"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Chip from "@material-ui/core/Chip"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import { ascending, nest, descending } from "d3"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useInView } from 'react-intersection-observer'
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import useSWR from "swr"

const server = Server.getServer()

function useQuestionnaireTypes(): QuestionnaireType[] | undefined {
  const { data } = useSWR("/api/get-type/", () => server.getQuestionnaireTypes())
  return data
}

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        contentWrapper: {
            backgroundColor: "#FFF",
            borderRadius: '30px 30px 0 0',
            minHeight: "calc(100vh - 55px)",
            paddingTop: "2.1rem"
        },

        tabs: {
            width: '100%',
            height: '48px',
            borderBottom: '1px solid #DDD',
            overflowY: 'auto',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
                display: 'none',
            }
        },

        tabsInner: {
            '& a': {
                display: 'inline-block',
                padding: '12px',
                fontSize: '.875rem',
                color: '#888',
                textDecoration: 'none',
                cursor: "pointer",
                "&:hover": {
                    textDecoration: "none",
                },
            },
            '& .current': {
                position: 'relative',
                '& p': {
                    fontWeight: 'bold',
                    color: '#000',
                },
                '&:after': {
                    content: "''",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: "100%",
                    height: "4px",
                    backgroundColor: "#000",
                },
            },
        },

        listgroup: {
            marginTop: "2.8rem",

            "&:first-child": {
                marginTop: "0",
            },
        },

        grouptitle: {
            fontSize: "1.5rem",
            fontWeight: "bold",
        },

        module: {
            display: "grid",
            flexWrap: "wrap",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "6px",
            "& > div a": {
                moduleItemInfoRight: {
                    display: "block",
                },
                itemInfoRight: {
                    display: "none",
                }
            },
            "& .info": {
                display: "none",
            },
            "& .info2": {
                display: "block",
                float: "none",
                "& .MuiBreadcrumbs-separator": {
                    margin: "0 4px",
                },
            },
            "& div.progress": {
                display: "block",
                float: "right",
                lineHeight: "1.9rem",
                fontSize: '.8125rem',
                color: "#4FA9BA",
                "& span": {
                    fontSize: '.8rem',
                }
            },
            "& div.progress2": {
                display: "none",
            },
        },

        item: {
            position: "relative",
            backgroundColor: "#fff",
            textDecoration: "none",
            "&:first-child": {
                marginTop: "0",
            },
            "& > a": {
                display: "block",
                cursor: "pointer",
                "&:hover": {
                    textDecoration: "none",
                },
            },
            "& .progress": {
                display: "none",
            },
            "& .progress2": {
                display: "block",
            },
        },

        itemimg: {
            display: "flex",
            height: "14.375rem",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "#F5F5F5",
            "@media (min-width: 600px)": {
                height: "20rem",
            },
            "& img": {
                maxWidth: "60%",
                maxHeight: "90%",
            },
        },

        itemtext: {
            padding: "1rem 1.25rem 1.25rem",
            fontSize: '0.875rem',
            fontFamily: 'Noto Sans CJK KR',
            color: '#999',
        },

        itemInfoLeft: {
            float: 'left',
            fontFamily: 'Noto Sans KR',
            fontWeight: 'normal',
            fontSize: '0.8125rem',
        },

        itemInfoRight: {
            float: 'right',
            "& li p": {
                fontFamily: "'Gotham Narrow', 'Noto Sans KR'",
                fontSize: '0.875rem',
                color: '#999',
            },
        },

        itemInfoRight2: {
            display: "none",
        },

        itemname: {
            fontSize: '1rem',
            color: '#000',
            clear:  'both',
        },

        labellist: {
            position: "absolute",
            top: "0.875rem",
            left: "0.875rem",
        },

        itemlabel: {
            borderRadius: "6px",
            fontSize: ".6875rem",
            "& span": {
                paddingTop: "2px",
            }
        },

        itemmodelname: {
            padding: "0.1rem 0 0.8rem",
            color: "#222",
            fontSize: "0.9375rem",
            fontWeight: 500,
            lineHeight: "1.3",
        },

        progressbar: {
            padding: ".3rem 1.25rem 1.875rem",
        },

        closed: {
            position: "relative",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            opacity: "0.5",
        },

        emptycase: {
            padding: "60px 0",
            textAlign: "center",
        },
    }),
)

interface Props {
    // items: Questionnaire[]
    tid: number
    category: string
    // fetchNext: () => void
}

export default function QuestionnaireList(props: Props): React.ReactElement {
    const user = useSelector(selectUser)
    const [page, setPage] = useState(1)
    const { data } = props.tid
        ? useSWR(`/api/questionnaires/type/${props.tid}?${props.category}`, () => server.getQuestionnaires(props.tid, props.category))
        : useSWR(`/api/questionnaires/`, () => server.getAllQuestionnaires(page))
    const [sort, setSort] = useState('newest')

    // useSWR(`/api/questionnaires/type/${props.tid}?${props.category}`, () =>
    //     props.tid === 0 ? server.getAllQuestionnaires(page) : server.getQuestionnaires(props.tid, props.category)
    // )

    const [questionnaire, setQuestionnaire] = useState<Questionnaire[]>([])
    const [ref, inView] = useInView()

    useEffect(() =>{
        if(data) {
            setQuestionnaire(data)
        }
    }, [data])

    useEffect(() => {
        if(inView && !props.tid) {
            fetchQuestionnaireData(page + 1)
        }
    }, [inView])

    const fetchQuestionnaireData = async (fetchPage: number) => {
        setPage(fetchPage)
        const fetchData = await server.getAllQuestionnaires(fetchPage)
        if(fetchData.length > 0) setQuestionnaire([...questionnaire, ...fetchData])
    }

    const history = useHistory()
    const [currentQid, setCurrentQid] = useState<number>(0)
    const [alignment, setAlignment] = useState(true)
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false)

    const questionnaireTypes = useQuestionnaireTypes()

    const sortUtil = (a: KVList<Questionnaire>, b: KVList<Questionnaire>, sort: string) => {
        if(sort === 'newest') {
            return ascending(a.values[0].createdAt, b.values[0].createdAt)
        } else if(sort === 'complete') {
            const initialValue = 0
            const aTotal = a.values.reduce((acum, curr) => {
                const a = curr.accumulatedDistance / curr.goalDistance
                return a + acum
            }, initialValue) / a.values.length
            const bTotal = b.values.reduce((acum, curr) => {
                const a = curr.accumulatedDistance / curr.goalDistance
                return a + acum
            }, initialValue) / a.values.length
            return ascending(aTotal, bTotal)
            // return ascending(a.values[0].goalDistance, b.values[0].goalDistance)
        } else if(sort === 'sizeAsc') {
            return ascending(b.values[0].size, a.values[0].size)
        } else if(sort === 'sizeDesc') {
            return descending(b.values[0].size, a.values[0].size)
        } else {
            return ascending(b.values[0].createdAt, a.values[0].createdAt)
        }
    }

    const classes = useStyles()
    const rowGroups = props.tid
            ? (nest<Questionnaire>()
                .key((d) => sort !== 'complete' ? d.modelName : String(d.id))
                .sortKeys(ascending)
                .sortValues((a, b) => ascending(b.createdAt, a.createdAt))
                .entries([...questionnaire]) as unknown) as KVList<Questionnaire>[] 
            // .entries([...props.items]) as unknown) as KVList<Questionnaire>[]
            : (nest<Questionnaire>()
                .key((d) => d.modelName)
                .sortValues((a, b) => ascending(b.createdAt, a.createdAt))
                .entries([...questionnaire]) as unknown) as KVList<Questionnaire>[]
    props.tid && rowGroups.sort((a, b) => sortUtil(b, a, sort))

    const defaultShoesImage = [defaultShoesImage1, defaultShoesImage2, defaultShoesImage3]

    const listItemStyle = (q: Questionnaire): { [index: string]: string } => {
        const borderColor = q.closed ? "#888" : q.submitted ? "#EF6156" : "#EF6156"
        const bgColor = q.closed ? "#888" : q.submitted ? "transparent" : "#EF6156"
        const color = q.closed ? "#fff" : q.submitted ? "#d1131c" : "#fff"
        return {
            backgroundColor: bgColor,
            border: `1px solid ${borderColor}`,
            color: color,
        }
    }

    function goLink(q: Questionnaire) {
        if (user?.tester) {
            if (q.submitted) {
                setConfirmDialog(true)
                setCurrentQid(q.id)
            } else {
                history.push(`/questionnaires/${q.id}`)
            }
        } else {
            history.push(`/comparison?qids=${q.id}`)
        }
    }

    function formatDate(d: number): string {
        return new Date(d).toLocaleDateString()
    }

    const toggleAlignment = () => {
        setAlignment(!alignment)
    }

    const listItem = (q: Questionnaire) => {
        console.log('list', q)
        return (
            <Grid container key={q.id}>
                <Grid
                    className={`${classes.item} ${q.closed ? classes.closed : ''}`}
                    item
                    xs={12}
                >
                    <Link color="initial" onClick={() => { goLink(q) }}>
                        <Box className={classes.itemimg}>
                            {/* TODO test 코드 제거  */}
                            {/* <img src={q.outerImage || defaultShoesImage[q.id]} alt="" /> */}
                            <img src={`https://srl.k2group.co.kr/${q.outerImage}` || defaultShoesImage[q.id]} alt="" />
                        </Box>
                        <Box className={classes.itemtext}>
                            <Box className={classes.labellist}>
                                <Chip
                                    className={classes.itemlabel}
                                    style={listItemStyle(q)}
                                    size="small"
                                    label={
                                        q.closed ? "마감" : q.submitted ? "수정 가능" : "참여 가능"
                                    }
                                />
                            </Box>
                            <div>
                                <Typography className={classes.itemInfoLeft}>{q.category}</Typography>
                                <Box className={`progress`}>
                                    <Typography>
                                        {
                                            q.typeName === '신발' ?
                                                (q.accumulatedDistance / q.goalDistance) * 100 >= 100 ? 100 : ((q.accumulatedDistance / q.goalDistance) * 100).toFixed(2)
                                                : null
                                        }
                                        {q.typeName === '신발' && <span>%</span>}
                                    </Typography>
                                </Box>
                                <Breadcrumbs separator="|" className={`info ${classes.itemInfoRight}`}>
                                    <Typography>{q.season}</Typography>
                                    <Typography>{q.size}</Typography>
                                    <Typography>{formatDate(q.createdAt)} ~</Typography>
                                </Breadcrumbs>
                            </div>
                            <Typography className={classes.itemname}>
                                {/* {q.name} */}{q.modelName}
                            </Typography>
                            <Breadcrumbs separator="|" className={`info2 ${classes.itemInfoRight} ${classes.itemInfoRight2}`}>
                                <Typography>{q.season}</Typography>
                                <Typography>{q.size}</Typography>
                                <Typography>{formatDate(q.createdAt)} ~</Typography>
                            </Breadcrumbs>
                        </Box>
                        {q.typeName === "신발" && (
                            <Box className={`progress2 ${classes.progressbar}`}>
                                <ProgressDistance
                                    distance={q.accumulatedDistance}
                                    totalDistance={q.goalDistance}
                                />
                            </Box>
                        )}
                    </Link>
                </Grid>
            </Grid>
        )
    }

    const listGroup = (g: any) => {
        return <div key={g.key}>
            {/* <div key={g.key} className={classes.listgroup}> */}
            {/* <Typography className={classes.grouptitle}>{g.key}</Typography> */}
            {g.values.map((q: Questionnaire) => listItem(q))}
        </div>
    }

    return (
        <div className={classes.contentWrapper}>
            <Helmet>
                <title>설문지 목록 | K2</title>
            </Helmet>
            <div className={classes.tabs}>
                <div className={classes.tabsInner}>
                    <a href={`/questionnaireList`} className={props.tid === 0 ? 'current' : ''}>
                        <Typography>전체{' '}</Typography>
                    </a>
                    {questionnaireTypes && questionnaireTypes.map((questionnaireType) => (
                        <a
                            key={questionnaireType.id}
                            className={props.tid === questionnaireType.id ? 'current': ''}
                            href={`/questionnaireList/${questionnaireType.id}`}>
                            <Typography>{questionnaireType.typeName}</Typography>
                        </a>
                    ))}
                </div>
            </div>
            <QuestionnaireUtil
                tid={props.tid}
                total={data?.length ? data?.length : 0}
                alignment={alignment}
                onAlignment={toggleAlignment}
                sortName={sort}
                sortFn={setSort}
            />
            {rowGroups.length < 1 ? (
                <Box className={classes.emptycase}>
                    <Box>
                        <ErrorOutlineIcon fontSize={"large"} htmlColor={"#ddd"} />
                        <Typography>조회 결과가 없습니다.</Typography>
                    </Box>
                </Box>
            ) : (
                    <Box className={alignment ? "" : classes.module}>
                            {rowGroups.map((g) => listGroup(g))}
                        <div style={{color: 'transparent'}} ref={ref}>inview</div>
                    </Box>
            )}
            {confirmDialog && (
                <ConfirmDialog
                    description="기존에 참여했던 설문입니다. 수정하시겠습니까?"
                    onClose={() => setConfirmDialog(false)}
                    onConfirm={() => {
                        history.push(`/questionnaires/${currentQid}`)
                        window.scrollTo(0, 0)
                    }}
                />
            )}
        </div>
    )
}
