// import defaultOuterImage from "../../assets/images/test_shoes_outer.png"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

interface Props {
    src: string
    alt: string
    dots: number[]
    like: boolean
}

const useStyles = makeStyles({
    root: {
        position: "relative",
        width: "100%",
        display: 'flex',

        "& img": {
            width: "100%",
            maxWidth: '230px',
        },

        "& svg": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "230px",
            height: "100%",
        },
    },
})

export default function NewHeatmap({ src, alt, dots, like }: Props): React.ReactElement {
    const classes = useStyles()
    const filterId = "heatmap" + String((Math.random() * 100000) | 0)

    function setCircle(datas: any) {
        return datas * 100 + "%"
    }

    return (
        <div className={classes.root}>
            <img src={src} alt={alt} />
        {/* <img src={defaultOuterImage} alt={alt} /> */}
        {/* <div style={{ width: '100px', height: '100px', backgroundColor: 'aquamarine', zIndex: 5}}></div> */}
            <svg>
                {/* <filter id={filterId}>
                    <feGaussianBlur stdDeviation="0.01" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 1 0
                                0 0 0 1 0
                                0 0 0 1 0
                                0 0 0 1 0"
                    />
                    <feComponentTransfer>
                        <feFuncR type="table" tableValues="0.14 0.15 0.58 1 0.56" />
                        <feFuncG type="table" tableValues="0.09 0.74 0.98 0.51 0.05" />
                        <feFuncB type="table" tableValues="0.11 0.88 0.32 0.11 0" />
                        <feFuncA type="table" tableValues="0 0.3 0.6 0.9 0.9" />
                    </feComponentTransfer>
                </filter> */}
                <g className="dots" filter={`url(${ window.location.href }#${ filterId })`}>
                  <circle
                      key={dots[0]}
                      cx={setCircle(dots[0])}
                      cy={setCircle(dots[1])}
                      r={10}
                      opacity={0.6}
                      fill={like ? 'blue' : 'red'}
                    />
                    {/* {dots.map((dot, i) => (
                        <circle
                            key={i}
                            cx={setCircle(dot[0])}
                            cy={setCircle(dot[1])}
                            r={10}
                            opacity={0.6}
                            fill="red"
                        />
                    ))} */}
                </g>
            </svg>
        </div>
    )
}