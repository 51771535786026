import { setMessage } from "./flashMessageSlice"
import { increase, decrease } from "./loadingSlice"
import { reload } from "./userSlice"
import { AppThunk } from "../app/store"
import { Server } from "../server"
import { Submission } from "../types"
import { mutate } from "swr"

const server = Server.getServer()

export const submit = (submission: Submission): AppThunk => async (dispatch) => {
    dispatch(increase())
    try {
        await server.saveSubmission(submission)
        mutate(["/api/questionnaires/{qid}/submission", submission.questionnaireId], {
            ...submission,
        })
    } finally {
        // const data = await server.getUser()
        // dispatch(setUser(data))
        dispatch(reload())
        dispatch(decrease())
        dispatch(setMessage("제출이 완료되었습니다."))
    }
}
