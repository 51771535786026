import questionareSubmitted from "../assets/images/questionareSubmitted.svg"
import { selectUser } from "../features/userSlice"
import Button from "@material-ui/core/Button"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import WarningIcon from "@material-ui/icons/Warning"
import React from "react"
import { useSelector } from "react-redux"

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        page: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 216px)",
            height: "calc(100vh - 120px)",
            padding: "0 20px",
            textAlign: "center",
        },
        text: {
            margin: "1.5rem 0",
            fontSize: "1rem",
            color: "#000",
        },
        buttonArea: {
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            width: "100%",
            margin: "25px 20px",
            zIndex: 12,
        },
        confirmBtn: {
            width: 'calc(100% - 40px)',
            margin: '0 20px',
            height: 55,
            borderRadius: 10,
            backgroundColor: '#EF6156',
            fontSize: '1rem',
            fontWeight: 600,
            color: '#fff',
            '&:hover': {
                backgroundColor: '#EF6156',
            }
        },
    }),
)

type Props = {
    questionnaireType: number
}

export default function NoPage(props: Props): React.ReactElement {
    const classes = useStyles()
    const user = useSelector(selectUser)

    return (
        <>
            <div className={classes.page}>
                <div>
                    <img src={questionareSubmitted} />
                </div>
                <Typography className={classes.text}>
                    설문에 참여해 주셔서 <br /> 감사합니다.
                </Typography>
            </div>
            <Button type="button" className={`${classes.confirmBtn}`} href={!user ? "/accounts/login" : `/questionnaireList/${props.questionnaireType}`}>
                {!user ? "로그인 화면으로 돌아가기" : "목록으로"}
            </Button>
        </>
    )
}