import { DefinitionItemHelp } from "../types"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"

interface Props {
    onClose: () => void
    title: string
    help: DefinitionItemHelp
}

const CustomDialog = withStyles({
    root: {
        "& .dialogTitle": {
            padding: "0.875rem 1.25rem",
            backgroundColor: "#f1f1f1",
        },
        "& .dialogTitle h2": {
            paddingRight: "1.5rem",
            fontSize: "1rem",
        },
        "& .dialogTitle button": {
            position: "absolute",
            top: "0",
            right: "1px",
            padding: "0.938rem",
        },
        "& .dialogContent": {
            padding: "1.25rem 1.25rem 1.625rem",
        },
        "& .dialogContent .imgwrap": {
            display: "flex",
            marginBottom: "1.25rem",
            overflowX: "auto",
        },
        "& .dialogContent img": {
            height: "180px",
            marginLeft: "5px",
            "&:first-child": {
                marginLeft: "0",
            },
            "&.one": {
                margin: "0 auto",
            },
        },
        "& .dialogContent dl": {
            margin: "0",
        },
        "& .dialogContent dt": {
            fontWeight: 500,
            fontSize: "1rem",
        },
        "& .dialogContent dd": {
            position: "relative",
            margin: "0.5rem 0 2rem",
            fontSize: "0.8125rem",
            whiteSpace: "pre-line",
            "&:last-child": {
                marginBottom: "0",
            },
        },
    },
})(Dialog) as any

export default function HelpDialog({ title, help, onClose }: Props): React.ReactElement {
    return (
        <CustomDialog fullWidth={true} maxWidth={"xs"} onClose={onClose} open>
            <DialogTitle className="dialogTitle">
                {title}
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className="dialogContent">
                {help.helpImages !== null && help.helpImages[0] !== "" && (
                    <div className="imgwrap">
                        {help.helpImages?.map((image) => {
                            console.log(image)
                            return <img key={image} src={image} className="one" alt="" />
                        })}
                    </div>
                )}
                {help.helpText && (
                    <dl>
                        <dt>도움말</dt>
                        <dd>{help.helpText}</dd>
                    </dl>
                )}
            </DialogContent>
        </CustomDialog>
    )
}
