import iconExpandMore from '../assets/images/expand_more.png'
import iconSortTypeT1 from "../assets/images/list_sorting_t1.png"
import iconSortTypeT2 from "../assets/images/list_sorting_t2.png"
import { Server } from "../server"
import { Filter } from "../types"
import Box from "@material-ui/core/Box"
import Chip from "@material-ui/core/Chip"
import Container from "@material-ui/core/Container"
import Drawer from '@material-ui/core/Drawer'
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import FilterListIcon from "@material-ui/icons/FilterList"
import queryString from "query-string"
import React, { useState, useRef, useEffect, useCallback } from "react"
import { useHistory } from "react-router"
import useSWR from "swr"

const useDrawerContentStyles = makeStyles({
    title: {
        marginTop: '20px',
        padding: '20px',
        lineHeight: '1.5rem',
        fontSize: '1rem',
    },
    sortItems: {
        padding: '0 20px 18px',
        '& li': {
            padding: '7.5px 0',
        },
    },
    filters: {
        position: 'relative',
        display: "grid",
        width: '100%',
        padding: '0 20px',
        flexWrap: "wrap",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        columnGap: "6px",
        textAlign: 'center',
    },
    filterTab: {
        "& p": {
            position: 'relative',
            display: 'inline-block',
            width: '100%',
            padding: '0 20px 20px',
            color: '#999',
        },
        "&.selected p": {
            color: '#000',
            '&::before': {
                content: "''",
                position: "absolute",
                bottom: '6px', left: "50%",
                width: 'calc(calc(100vw - 40px)/4)',
                // inlineSize: '120%',
                height: "3px",
                transform: "translateX(-50%)",
                backgroundColor: "#000",
            },
        },
        "&.selected $filterWrap": {
            display: 'block',
        },
    },
    filterWrap: {
        position: 'absolute',
        top: '38px',
        left: '0',
        display: 'none',
        width: '100%',
        height: "calc(100vh - 283px)",
        borderTop: '1px solid #ddd',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        overscrollBehavior: 'contain',
        textAlign: 'left',
    },
    brandFilters: {
        padding: '12px 40px',
        backgroundColor: '#F5F5F5',
        textAlign: 'right',
        '& label': {
            paddingRight: '10px',
        }
    },
    filterItems: {
        display: "grid",
        width: '100%',
        padding: '0 20px',
        flexWrap: "wrap",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "6px",
    },
    filterItem: {
        position: 'relative',
        padding: '10px 0',
        lineHeight: '20px',
    },
    buttonArea: {
        position: "fixed",
        bottom: 0,
        left: 20,
        right: 20,
        display: "grid",
        flexWrap: "wrap",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "10px",
        marginBottom: '25px',
        '& button': {
            width: '100%',
            height: 55,
            borderRadius: 10,
            fontSize: '1rem',
        },
    },
    buttonArea2: {
        gridTemplateColumns: "30% 70%",
    },
    cancelBtn: {
        backgroundColor: '#F4F4F4',
        color: '#999',
        '&:hover': {
            backgroundColor: '#F4F4F4',
        }
    },
    confirmBtn: {
        backgroundColor: '#EF6156',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#EF6156',
        }
    },
})

interface SortProps {
    sortName: string
    sortFn: (s: string) => void
    closeDrawer: () => void
}

const SortDrawerContent = ({ sortName, sortFn, closeDrawer }: SortProps) => {
    const classes = useDrawerContentStyles()
    const [sort, setSort] = useState(sortName)

    return (
        <Container maxWidth="sm">
            <Typography variant="h6" className={classes.title}>정렬 기준</Typography>
            <ul className={classes.sortItems}>
                <li>
                    <label className="custom-chk">
                        <input
                            type="radio"
                            name='sort'
                            defaultChecked={sort === 'newest'}
                            onClick={() => setSort('newest')}/>
                        <div className="checkmark"></div>
                        <span>최신순</span>
                    </label>
                </li>
                <li>
                    <label className="custom-chk">
                        <input
                            type="radio"
                            name='sort'
                            defaultChecked={sort === 'complete'}
                            onClick={() => setSort('complete')}/>
                        <div className="checkmark"></div>
                        <span>달성률순</span>
                    </label>
                </li>
                <li>
                    <label className="custom-chk">
                        <input
                            type="radio"
                            name='sort'
                            defaultChecked={sort === 'sizeAsc'}
                            onClick={() => setSort('sizeAsc')}/>
                        <div className="checkmark"></div>
                        <span>사이즈 오름차순</span>
                    </label>
                </li>
                <li>
                    <label className="custom-chk">
                        <input
                            type="radio"
                            name='sort'
                            defaultChecked={sort === 'sizeDesc'}
                            onClick={() => setSort('sizeDesc')}/>
                        <div className="checkmark"></div>
                        <span>사이즈 내림차순</span>
                    </label>
                </li>
            </ul>
            <div className={classes.buttonArea}>
                <button type="button" className={`${classes.cancelBtn}`} onClick={closeDrawer}>취소</button>
                <button type="button" className={`${classes.confirmBtn}`} onClick={() => { sortFn(sort); closeDrawer() }}>확인</button>
            </div>
        </Container>
    )
}

interface FilterItemProps {
    id: string
    pValues: string | null
    pValues2: string | null
    filterName: string
    options: { name: string; id: number; own?: string }[]
    onChange: (value: string | null, id: string) => void
}

function QuestionnaireFilterItems({
    id,
    pValues,
    pValues2,
    filterName,
    options,
    onChange,
}: FilterItemProps): React.ReactElement {
    const myRef = useRef(null)
    const [check, setCheck] = useState('all')
    const [, updateState] = useState<any>()
    const forceUpdate = useCallback(() => updateState({}), [])
    console.log(options, pValues, pValues2)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>, id: string) {
        if (!myRef.current) {
            return
        }

        if (event.target.value === 'all') {
            Array.from((myRef.current as HTMLInputElement).querySelectorAll('input:not([value="all"]):not([name="filter-owns"])')).forEach((checkbox) => {
                (checkbox as HTMLInputElement).checked = false
            })
            if (event.target.value) {
                onChange('', id)
            } else {
                onChange(null, id)
            }
            return
        }

        const selectedOptions = Array.from(Array.from((myRef.current as HTMLInputElement).querySelectorAll('input[type="checkbox"]:checked:not([name="filter-owns"])')).map((checkbox) => (checkbox as HTMLInputElement).value))

        const allElement = (myRef.current as HTMLInputElement).querySelectorAll('input[value="all"]:checked')

        if(allElement.length > 0 && Array.isArray(selectedOptions)) {
            selectedOptions.splice(selectedOptions.indexOf('all'), 1);
            (allElement[0] as HTMLInputElement).checked = false
        }

        onChange(selectedOptions.join(',') as string, id)
    }

    function handleChange2(e:  React.MouseEvent<HTMLInputElement, MouseEvent>) {
        if (!myRef.current) {
            return
        }
        
        // const selectedOptions = Array.from(
        //     Array.from(
        //         (myRef.current as HTMLInputElement).querySelectorAll('input[type="radio"][name="filter-owns"]:checked')
        //     ) .map((radio) => (radio as HTMLInputElement).value )
        // )
        const selectedOptions = Array.from(Array.from((myRef.current as HTMLInputElement).querySelectorAll('input[type="checkbox"]:checked:not([name="filter-owns"])')).map((checkbox) => (checkbox as HTMLInputElement).value)) || [',']
        const allElement = (myRef.current as HTMLInputElement).querySelectorAll('input[value="all"]:checked')

        if(allElement.length > 0 && Array.isArray(selectedOptions)) {
            selectedOptions.splice(selectedOptions.indexOf('all'), 1);
            (allElement[0] as HTMLInputElement).checked = false
        }

        console.log('selected options', selectedOptions)
        onChange(selectedOptions.join(',') as string, 'brand')

        // 전체는 체크가 되어있는지 확인해서 체크 해제일 경우 빈  값 보내ㅣㄱ
        // console.log(options)
        // console.log(e.target?.value)
        const target = e.target as HTMLInputElement
        setCheck(target.value)
        onChange(target.value, 'owns')
        // onChange(selectedOptions.join(',') as string, 'owns')
    }

    const classes = useDrawerContentStyles()
    return (
        <div className={`filterWrap ${classes.filterWrap}`} ref={myRef}>
            {
                filterName === '브랜드'
                && <div className={classes.brandFilters} onClick={(e) => e.stopPropagation()}>
                    <label className={`custom-chk`}>
                        <input
                            type="checkbox"
                            name={`filter-${id}`}
                            value="all"
                            checked={check === 'all' ? true : false}
                            onClick={(e) => handleChange2(e)}
                        />
                        <div className="checkmark"></div>
                        <span>전체</span>
                    </label>
                    <label className={`custom-chk`}>
                        <input
                            type="checkbox"
                            name={`filter-owns`}
                            value="true"
                            checked={check === 'true' ? true : false}
                            onClick={(e) => handleChange2(e)}
                        />
                        <div className="checkmark"></div>
                        <span>자사</span>
                    </label>
                    <label className={`custom-chk`}>
                        <input
                            type="checkbox"
                            name={`filter-owns`}
                            value="false"
                            checked={check === 'false' ? true : false}
                            onClick={(e) => handleChange2(e)}
                        />
                        <div className="checkmark"></div>
                        <span>타사</span>
                    </label>
                </div>
            }
            <ul className={classes.filterItems}>
                {
                    filterName !== '브랜드' &&
                    <li className={classes.filterItem} onClick={(e) => e.stopPropagation()}>
                        <label className={`custom-rdo`}>
                            <input
                                type="radio"
                                name={`filter-${id}`}
                                value="all"
                                defaultChecked={pValues === ''}
                                onClick={(e) => handleChange(e as unknown as React.ChangeEvent<HTMLInputElement>, id)}
                            />
                            <div className="checkmark"></div>
                            <span>전체</span>
                        </label>
                    </li>
                }
                {options
                    .filter((item) => pValues === null 
                        ? item 
                        : check === 'true' 
                            ? item?.own === 'own'
                                : check === 'false' 
                                    ? item?.own === 'other'
                                    : item
                    )
                    .map((option) => {
                        return (
                        <li key={`${option.id}`} className={classes.filterItem} onClick={(e) => e.stopPropagation()}>
                            <label className={`custom-rdo`}>
                                <input
                                    type="checkbox"
                                    name={`filter-${id}`}
                                    value={option.id}
                                    defaultChecked={!!(pValues) && pValues.length > 0 && pValues.split(',').includes(option.id.toString())}
                                    onClick={(e) => handleChange(e as unknown as React.ChangeEvent<HTMLInputElement>, id)}
                                />
                                <div className="checkmark"></div>
                                <span>{option.name}</span>
                            </label>
                        </li>
                    )})}
            </ul>
        </div>
    )
}

interface FilterProps {
    filters: Filter[] | undefined
}

const FilterDrawerContent = ({ filters }: FilterProps) => {
    const [selecetdTab, setSelecetdTab] = useState(0)
    const [values, setValues] = useState<{[str: string]: any}>(queryString.parse(location.search))
    const classes = useDrawerContentStyles()
    const history = useHistory()
    const [, updateState] = useState<any>()
    const forceUpdate = useCallback(() => updateState({}), [])

    useEffect(() => {
        setValues({brand: '0', own: 'all'})
    }, [])

    useEffect(() => {
        // forceUpdate()
        console.log(values)
    }, [values])

    function handleValuesChange(pValues: string | null, id: string) {
        setValues({
            ...values,
            [id]: pValues,
        })
        console.log('values', pValues, values)
    }

    function handleRefresh() {
        history.push(`${location.pathname}`)
    }

    const confirmValuesChange = () => {
        {filters && filters.map((filter) => {
            if (values[filter.key] === "") delete values[filter.key]
        })}

        history.push({
            pathname: `${location.pathname}`,
            search: `?${decodeURIComponent(queryString.stringify(values))}`,
        })
    }

    return (
        <Container maxWidth="sm" >
            <Typography variant="h6" className={classes.title}>필터</Typography>
            <div className={`${classes.filters}`}>
                {filters && filters.map((filter, idx) => {
                    return (
                    <div className={`${classes.filterTab} ${selecetdTab === idx ? 'selected' : ''}`} key={filter.key}>
                        <Typography id={filter.key} onClick={(e) => {
                            e.stopPropagation()
                            setSelecetdTab(idx)
                        }}>
                            {filter.values.filter_name}
                        </Typography>
                        <QuestionnaireFilterItems
                            id={filter.key}
                            pValues={values[filter.key]? values[filter.key] : null}
                            pValues2={values['owns']? values['owns'] : null}
                            filterName={filter.values.filter_name}
                            options={filter.values.options}
                            onChange={handleValuesChange}
                        />
                    </div>
                )})}
            </div>
            <div className={`${classes.buttonArea} ${classes.buttonArea2}`}>
                <button type="button" className={`${classes.cancelBtn}`} onClick={handleRefresh}>초기화</button>
                <button type="button" className={`${classes.confirmBtn}`} onClick={() => { confirmValuesChange() }}>적용</button>
            </div>
        </Container>
    )
}

const server = Server.getServer()

function useFilter(tid: number): Filter[] | undefined {
    const { data } = useSWR(`/api/questionnaires-filter/${tid}`, () => server.getFilterList(tid))

    if (data === undefined) return undefined

    console.log('use filter', data)

    const filterData = Object.keys(data).sort().map((key) => {
        return {
            key,
            values: data[key],
        }
    })

    return filterData
}

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        utilbtnwrap: {
            display: "flex",
            width: "100%",
            marginTop: '30px',
            padding: '0 12px 0 20px',
            justifyContent: "space-between",
            lineHeight: '.875rem',
            fontSize: '.875rem',
        },
        filterbtns: {
            display: 'flex',
            "& button": {
                padding: "0 8px",
            },
            "& button span": {
                lineHeight: '.875rem',
                fontSize: '.875rem',
            },
        },
        filterlist: {
            width: "100%",
            marginTop: "0.5rem",
            padding: "0 20px 12px",
            "& div + div": {
                marginLeft: "0.4rem",
            },
        },
        listWrapper: {
            '& .MuiBackdrop-root': {
                background: 'none',
            },
            '& .MuiDrawer-paper': {
                borderRadius: '30px 30px 0 0',
            }
        },
        drawerPaper1: {
            width: 'auto',
            height: 'auto',
            paddingBottom: '80px',
            border: 'none',
            '&::before': {
                content: "''",
                position: "absolute",
                top: '10px', left: '50%',
                width: "50px",
                height: "5px",
                transform: "translateX(-50%)",
                backgroundColor: "#F0F0F0",
                borderRadius: "3px",
            },
            '& .MuiContainer-root': {
                padding: 0,
            }
        },
        drawerPaper2: {
            width: 'auto',
            height: "calc(100vh - 58px)",
            border: 'none',
            '&::before': {
                content: "''",
                position: "absolute",
                top: '10px', left: '50%',
                width: "50px",
                height: "5px",
                transform: "translateX(-50%)",
                backgroundColor: "#F0F0F0",
                borderRadius: "3px",
            },
            '& .MuiContainer-root': {
                padding: 0,
            }
        },
    }),
)

interface Props {
    tid: number
    total: number
    alignment: boolean
    onAlignment: () => void
    sortName: string
    sortFn: (sort: string) => void
}

export default function QuestionnaireUtil({
    tid,
    total,
    alignment,
    onAlignment,
    sortName,
    sortFn,
}: Props): React.ReactElement {
    const classes = useStyles()
    const filters = useFilter(tid)
    const [filterPopup, setFilterPopup] = useState(false)
    const [sortPopup, setSortPopup] = useState(false)

    function handleAlignment() {
        onAlignment()
    }

    function renderSortTitle(n: string) {
        if(n === 'newest') {
            return '최신순 '
        } else if(n === 'complete') {
            return '달성율순 '
        } else if(n === 'sizeAsc') {
            return '사이즈 오름차순 '
        } else if(n === 'sizeDesc') {
            return '사이즈 내림차순 '
        }
    }

    function makeChip(filter: any, filterList: any) {
        const arrChip = []
        if (filter && filter.length != undefined) {
            for (let i = 0; i < filter.length; i++) {
                if (filterList && filterList.length != undefined) {
                    for (let j = 0; j < filterList.length; j++) {
                        if (filter[i] == filterList[j].key) {
                            if (
                                filterList[j].values.options &&
                                filterList[j].values.options.length != undefined
                            ) {
                                const filterV = Object.values(queryString.parse(location.search))[i]
                                for (let k = 0; k < filterList[j].values.options.length; k++) {
                                    if (filterList[j].values.options[k].id == filterV)
                                        arrChip.push(filterList[j].values.options[k].name)
                                }
                            }
                        }
                    }
                }
            }
        }
        return arrChip.map((data, idx) => <Chip key={idx} label={data} />)
    }

    return (
        <Box>
            <Box className={classes.utilbtnwrap}>
                <Box>
                    <span>총 {total}개</span>
                </Box>
                <Box className={classes.filterbtns}>
                    <button>
                        <Typography
                            component="span"
                            onClick={(e) => {
                                setSortPopup(!sortPopup)
                                document.getElementById('dim')?.classList.toggle('show')
                            }}
                        >
                            {renderSortTitle(sortName)}
                            <img src={iconExpandMore} />
                        </Typography>
                        <Drawer
                            className={classes.listWrapper}
                            classes={{
                                paper: classes.drawerPaper1,
                            }}
                            anchor={'bottom'}
                            open={sortPopup}
                        >
                            <SortDrawerContent
                                sortName={sortName}
                                sortFn={sortFn}
                                closeDrawer={() => {
                                    setSortPopup(!sortPopup)
                                    document.getElementById('dim')?.classList.toggle('show')
                                }}
                            />
                        </Drawer>
                    </button>
                    <button onClick={(e) => {
                        setFilterPopup(!filterPopup)
                        document.getElementById('dim')?.classList.toggle('show')
                        e.stopPropagation()
                    }}>
                        <FilterListIcon fontSize="small" />
                        <Drawer
                            className={classes.listWrapper}
                            classes={{
                                paper: classes.drawerPaper2,
                            }}
                            open={filterPopup}
                            anchor={'bottom'}
                        >
                            <FilterDrawerContent filters={filters} />
                        </Drawer>
                    </button>
                    <button onClick={handleAlignment}>
                        <img src={alignment ? iconSortTypeT1 : iconSortTypeT2} />
                    </button>
                </Box>
            </Box>
            <Box className={classes.filterlist}>
                {makeChip(Object.keys(queryString.parse(location.search)), filters)}
            </Box>
        </Box>
    )
}
