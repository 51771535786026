import NewHeatmap from './NewHeatMap'
import defaultOuterImage from "../../assets/images/test_shoes_outer.png"
// import Heatmap from "./Heatmap"
import { DefinitionItemMarkOnImages, KVList, Questionnaire, SubmissionRow } from "../../types"
import { flatten } from "../../utils"
import Button from "@material-ui/core/Button"
// import Collapse from "@material-ui/core/Collapse"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
// import { ascending } from "d3"
import React, { useState } from "react"
import { Link } from "react-router-dom"

const useStyles = makeStyles({
    reporttable: {
        padding: "1.25rem 1.5625rem",
        borderWidth: "0 1px 1px 0",
        borderStyle: "solid",
        borderColor: "#ECEBED",
        boxShadow: "none",

        "& .MuiTableCell-root": {
            fontSize: "0.75rem",
            verticalAlign: "top",
        },

        "& .MuiTableCell-head": {
            width: "12.5rem",
            padding: "0.75rem 1rem",
            borderTop: "1px solid #ccc",
            borderBottomColor: "#ccc",
            backgroundColor: "#f9f9f9",
            fontSize: "0.875rem",

            "& a": {
                color: "#333",
                fontSize: "0.875rem",
                fontWeight: 500,
                lineHeight: "1.5rem",
                textDecoration: "none",
            },
        },
    },

    textlist: {
        padding: "0",
        margin: "0.625rem 0 0",

        "& li": {
            position: "relative",
            paddingLeft: "1.625rem",
            marginBottom: "0.313rem",
            lineHeight: "1.25rem",
            listStyle: "none",
            textAlign: "left",

            "&:last-child": {
                marginBottom: "0",
            },
        },

        "& span": {
            position: "absolute",
            top: "0",
            left: "0",
            width: "1.25rem",
            height: "1.25rem",
            backgroundColor: "#f0f0f0",
            borderRadius: "50%",
            fontSize: "0.625rem",
            fontWeight: "bold",
            lineHeight: "1.25rem",
            textAlign: "center",
        },
    },
    number: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "1.25rem",
        height: "1.25rem",
        backgroundColor: "#f0f0f0",
        borderRadius: "50%",
        fontSize: "0.625rem",
        fontWeight: "bold",
        lineHeight: "1.25rem",
        textAlign: "center",
    },
    goodBadWrapper: {
        position: 'relative',
        display: 'flex',
    },
    desc: {
        position: "relative",
        paddingLeft: "1.625rem",
        marginBottom: "0.313rem",
        lineHeight: "1.25rem",
        listStyle: "none",
        textAlign: "left",

        "&:last-child": {
            marginBottom: "0",
        },
    }
})

interface Props {
    questionnaires: Questionnaire[]
    item: DefinitionItemMarkOnImages
    data: KVList<KVList<SubmissionRow>>[]
}

export default function ReportMarkOnImages({
    questionnaires,
    item,
    data,
}: Props): React.ReactElement {
    const classes = useStyles()
    const [checked, setChecked] = useState(true)

    const handleCheckChange = () => {
        setChecked((prev) => !prev)
    }

    return (
        <TableContainer className={classes.reporttable} component={Paper}>
            <Button
                endIcon={checked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                onClick={handleCheckChange}
            >
                코멘트 {checked ? "닫기" : "열기"}
            </Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {questionnaires.map((q) => (
                            <TableCell key={q.id} align="center">
                                <Link to={`/questionnaires/${q.id}`}>{q.modelName}</Link>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {item.images.map((image) => {
                        return (
                            <TableRow key={image.key}>
                                <TableCell component="th" scope="row">
                                    {image.label}
                                </TableCell>
                                {questionnaires?.map((q) => {
                                    // const imageUrl = (q as any)[image.key] as string
                                    const keyPrefix = `B:1:${image.key}:`
                                    const curData = flatten(
                                        flatten(
                                            data
                                                .filter((d) => d.key.startsWith(keyPrefix))
                                                .map((d) => d.values),
                                        ).map((d) => d.values),
                                    ).filter((d) => +d.questionnaireId === q.id)
                                    const dots = curData.map((raw) =>
                                        raw.value.split(",").map((token) => +token),
                                    )
                                    // const texts = curData.map((raw) => raw.text).sort(ascending)
                                    // const texts = curData.map((raw) => raw.text)
                                    const imgData = curData.map((raw) => ({text: raw.text, like: raw.like}))
                                    return (
                                        <TableCell key={q.id} align="center">
                                            {
                                                dots.map((dot, key) => {
                                                    return (
                                                        <div key={key}>
                                                            {/* <NewHeatmap src={imageUrl} alt={image.label} dots={dot} /> */}
                                                            <NewHeatmap src={`https://srl.k2group.co.kr/${q.outerImage}` || defaultOuterImage} alt={image.label} dots={dot} like={imgData[key].like === 'True'}/>
                                                            <div className={classes.goodBadWrapper}>
                                                                <span className={classes.number}>{key + 1}</span>
                                                                {/* <span className={classes.desc}>{texts[key]}</span> */}
                                                                <span className={classes.desc}>{imgData[key].like === 'True' ? '좋아요' : '나빠요'}</span>
                                                                <span className={classes.desc}>{imgData[key].text}</span>
                                                            </div>
                                                            {/* <ul className={classes.textlist}>
                                                                {texts.map((text: string, index: number) => (
                                                                    <li key={index}>
                                                                        <span>{index + 1}</span>
                                                                        {text}
                                                                    </li>
                                                                ))}
                                                            </ul> */}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}