import defaultInnerImage from "../../assets/images/test_shoes_inner.png"
import defaultOuterImage from "../../assets/images/test_shoes_outer.png"
import defaultSoleImage from "../../assets/images/test_shoes_sole.png"
import { DefinitionItemMarker, Questionnaire } from "../../types"
import MarkableImage from "../MarkableImage"
import { Box } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"

interface ItemMarkOnImagesProps {
    questionnaire: Questionnaire
    question: string
    images: {
        key: string
        label: string
    }[]
    payloadKey: string
    value: Record<string, DefinitionItemMarker[]>
    onChange: (payloadKey: string, value: Record<string, DefinitionItemMarker[]>) => void
}

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        itemBox: {
            // margin: "2rem 0 4rem",
            "&:last-child": {
                marginBottom: "0",
            },
            "& >div": {
                padding: "1.5rem 0 3rem",
                borderBottom: "1px solid #f1f1f1",
            },
        },
        itemTitle: {
            marginTop: "20px",
            fontWeight: 500,
            lineHeight: "1.4",
        },
    }),
)

function ItemMarkOnImages(props: ItemMarkOnImagesProps) {
    const classes = useStyles()
    const defaultImages = {
        soleImage: defaultSoleImage,
        innerImage: defaultInnerImage,
        outerImage: defaultOuterImage,
    }
    console.log(props.value)

    return (
        <Box className={classes.itemBox}>
            <Typography
                variant="subtitle1"
                component={"h3"}
                gutterBottom
                className={classes.itemTitle}
            >
                {props.question}
            </Typography>
            {props.images.map((image) => {
                const imageUrl =
                    (props.questionnaire as any)[image.key] || (defaultImages as any)[image.key]
                {/* TODO test 코드 제거  */}
                return (
                    <MarkableImage
                        key={image.label}
                        imageSrc={`https://srl.k2group.co.kr/${imageUrl}`}
                        label={image.label}
                        markers={props.value[image.key] || []}
                        onChange={(markers) =>
                            props.onChange(props.payloadKey, {
                                ...props.value,
                                [image.key]: markers,
                            })
                        }
                        disabled={false}
                    />
                )
            })}
        </Box>
    )
}

export default React.memo(ItemMarkOnImages)