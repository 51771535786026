import App from "./App"
import { store } from "./app/store"
import * as serviceWorker from "./serviceWorker"
import CssBaseline from "@material-ui/core/CssBaseline"
import {
    MuiThemeProvider,
    unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core/styles"
import axios from "axios"
import React from "react"
import { CookiesProvider } from "react-cookie"
import ReactDOM from "react-dom"
import { HelmetProvider } from "react-helmet-async"
import { Provider } from "react-redux"
import "./index.scss"

axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const helmetContext = {}

// 기존에 있는 style 을 변경할 때
const globalTheme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    backgroundColor: "#f5f5f5",
                },
            },
        },
    },
    typography: {
        fontFamily: "'Roboto', 'Noto Sans KR', 'Helvetica', 'Arial', sans-serif",
    },
    palette: {
        primary: {
            main: "#d1131c",
        },
        secondary: {
            main: "#3E73CF",
        },
        background: {
          default: "#f5f5f5",
        }
    },
})

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <Provider store={store}>
                <MuiThemeProvider theme={globalTheme}>
                    <CssBaseline />
                    <HelmetProvider context={helmetContext}>
                        <App />
                    </HelmetProvider>
                </MuiThemeProvider>
            </Provider>
        </CookiesProvider>
    </React.StrictMode>,
    document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
