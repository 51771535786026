import flashMessageReducer from "../features/flashMessageSlice"
import loadingReducer from "../features/loadingSlice"
import percentageReducer from "../features/percentageSlice"
import userReducer from "../features/userSlice"
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"

export const store = configureStore({
    reducer: {
        user: userReducer,
        loading: loadingReducer,
        flashMessage: flashMessageReducer,
        percentage: percentageReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
