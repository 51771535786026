import { Box } from '@material-ui/core'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import { StepIconProps } from '@material-ui/core/StepIcon'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { makeStyles, withStyles, createStyles, Theme } from "@material-ui/core/styles"
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '112%',
            overflow: 'hidden',
            '& .MuiStepper-root': {
                padding: '24px 0 15px',
            },
            '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                marginTop: '-34px',
                fontSize: '12px',
                color: '#999',
                // color: '#EF6156',
            },
            '& .MuiStepLabel-active.MuiStepLabel-alternativeLabel': {
                fontWeight: 'bold',
                color: '#EF6156',
            },
            '& .MuiStepLabel-completed.MuiStepLabel-alternativeLabel': {
                color: '#EF6156',
            },
        },
        root: {
            width: '100%',
            backgroundColor: '#F5F5F5',
            paddingRight: 5,
            paddingLeft: 5,
            '& > div': {
                // backgroundColor: 'red',
            }
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        labelActive: {
            color: '#EF6156',
        }
    }),
)

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 6,
        left: '-50%',
        // transform: 'translateX(50%)',
        width: '102%',
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#fff',
        borderRadius: 3,
        // backgroundImage:
        //     'blue',
    },
    active: {
        color: '#EF6156',
        '& $line': {
            // backgroundImage:
            //   '#EF6156 !important',
            backgroundColor: '#EF6156'
        },
    },
    completed: {
        color: '#EF6156',
        '& $line': {
            // backgroundImage:
            //   '#EF6156 !important',
            backgroundColor: '#EF6156'
        },
    },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
    root: {
        zIndex: 1,
        display: 'flex',
        width: 15,
        height: 15,
        color: 'transparent',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        border: '3px solid #EF6156',
        backgroundColor: '#fff',
    },
    completed: {
        // border: '3px solid #EF6156',
        // backgroundColor: '#fff',
    }
})

function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            <RadioButtonUncheckedIcon />,
        </div>
    )
}

interface Props {
    step: number
    stepMax: number
}

function K2Stepper({ step, stepMax }: Props): React.ReactElement {
    const classes = useStyles()

    const itemCount = stepMax - 1
    const StepItems = []

    for (let i = 0; i <= itemCount; i++) {
        StepItems.push(
            <Step key={i}>
                <StepLabel className={`${i === step ? `${classes.labelActive}` : ''}`} StepIconComponent={ColorlibStepIcon}>{`0${i + 1}`}</StepLabel>
            </Step>
        )
    }

    return (
        <Box className={classes.container}>
            <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />} className={classes.root}>
                {StepItems}
            </Stepper>
        </Box>
    )
}

export default K2Stepper