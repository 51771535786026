import { increase, decrease } from "./loadingSlice"
import { AppThunk, RootState } from "../app/store"
import { Server } from "../server"
import { User } from "../types"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const server = Server.getServer()

interface UserState {
    user: User | null
}

export const initialState: UserState = {
    user: null,
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload
        },
    },
})

export const { setUser } = userSlice.actions

export const reload = (): AppThunk => async (dispatch) => {
    dispatch(increase())
    try {
        const data = await server.getUser()
        if (data == null) {
            window.location.href = "/accounts/login?next=" + location.pathname
        }
        dispatch(setUser(data))
    } finally {
        dispatch(decrease())
    }
}

export const submit = (permission: string): AppThunk => async (dispatch) => {
    dispatch(increase())
    try {
        await server.savePermission(permission)
    } finally {
        dispatch(reload())
        dispatch(decrease())
    }
}

export const selectUser = (state: RootState): User | null => state.user.user

export default userSlice.reducer
