import defaultInnerImage from "../../assets/images/test_shoes_inner.png"
import defaultOuterImage from "../../assets/images/test_shoes_outer.png"
import defaultSoleImage from "../../assets/images/test_shoes_sole.png"
import LikertScaleSeven from "../../components/LikertScaleSeven"
import MarkableImage from "../../components/MarkableImage"
import { selectUser } from "../../features/userSlice"
import { Server } from "../../server"
import { DynamicItem, Questionnaire as QuestionnaireData, Submission } from "../../types"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import CreateIcon from "@material-ui/icons/Create"
import React from "react"
import { useSelector } from "react-redux"
import useSWR from "swr"

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        individualreport: {
            width: "830px",
            margin: "5rem auto 0",
            backgroundColor: "#fff",
            "@media print": {
                margin: "2rem auto 0",
            },
        },
        questionnaireitem: {
            marginBottom: "2rem",
        },
        boxwrap: {
            marginBottom: "1.875rem",
            "& h3": {
                marginBottom: "0.875rem",
                fontWeight: "500",
            },
        },
        boxdata: {
            marginBottom: "0.625rem",
            padding: "1.25rem 1.5rem",
            border: "1px solid #ddd",
            "& img": {
                display: "block",
                width: "50%",
                margin: "0 auto",
            },
            "&.divide": {
                display: "flex",
                border: "0",
                padding: "0",
                margin: "0 -0.313rem",
                marginTop: "0.625rem",
                "& > div": {
                    flex: "1",
                    border: "1px solid #ddd",
                    margin: "0 0.313rem",
                    padding: "1.25rem 1.5rem",
                },
            },
        },
        reporttop: {
            position: "relative",
            paddingRight: "5.625rem",
            "& h2": {
                marginBottom: "1.875rem",
            },
            "& .btnwrap": {
                position: "absolute",
                right: "0",
                top: "0",
                "@media print": {
                    display: "none",
                },
            },
        },
        reporttable: {
            borderRadius: "0",
            boxShadow: "none",
            "&.unit": {
                "& .MuiTableCell-alignRight": {
                    "&>div": {
                        paddingRight: "3.5rem",
                    },
                },
                "& span": {
                    position: "absolute",
                    top: "0.625rem",
                    right: "0",
                    width: "3.125rem",
                    textAlign: "left",
                },
            },
            "& .MuiTable-root": {
                borderTop: "2px solid #ddd",
                "& th": {
                    width: "8.75rem",
                    fontWeight: 500,
                    "&>div": {
                        backgroundColor: "#f0f0f0",
                    },
                },
            },
            "& .MuiTableCell-root": {
                position: "relative",
                width: "17.5rem",
                padding: "0",
                fontSize: "0.75rem",
                border: "0",
                backgroundColor: "transparent",
                "&>div": {
                    minHeight: "2.375rem",
                    padding: "0.625rem",
                    borderBottom: "1px solid #ddd",
                },
            },
        },
        imagelist: {
            padding: "0",
            display: "flex",
            flexWrap: "wrap",
            borderTop: "1px solid #ddd",
            "& li": {
                width: "50%",
                listStyle: "none",
                padding: "1rem",
                textAlign: "center",
                borderBottom: "1px solid #ddd",
                "& img": {
                    width: "100%",
                },
                "&:nth-child(2n+1)": {
                    borderRight: "1px solid #ddd",
                },
            },
        },
        textlist: {
            margin: "2rem 0 0",
            padding: "0",
            "& li": {
                position: "relative",
                marginTop: "0.625rem",
                padding: "1px 0 2px 1.875rem",
                listStyle: "none",
                "& span": {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "1.5rem",
                    height: "1.5rem",
                    fontWeight: "bold",
                    fontSize: "0.75rem",
                    lineHeight: "1.5rem",
                    backgroundColor: "#f1f1f1",
                    textAlign: "center",
                    borderRadius: "50%",
                },
                "& p": {
                    fontSize: "0.875rem",
                },
            },
        },
        listtype: {
            display: "flex",
            padding: "0",
            "& li": {
                flex: "1",
                padding: "0 0 0.625rem",
                listStyle: "none",
                textAlign: "center",
                borderLeft: "1px solid #ddd",
                color: "#666",
                "&:first-child": {
                    borderLeft: "0",
                },
                "& strong": {
                    color: "#333",
                    fontSize: "2rem",
                    "& span": {
                        paddingLeft: "5px",
                        fontSize: "0.875rem",
                    },
                },
            },
        },
        reson: {
            marginBottom: "5px",
            fontSize: "0.75rem",
            color: "#666",
        },
    }),
)

const server = Server.getServer()

type Props = { questionnaireId: number; submissionId: number }

function formatDate(d: number): string {
    return new Date(d).toLocaleDateString()
}

export default function Individual({ questionnaireId, submissionId }: Props): React.ReactElement {
    const user = useSelector(selectUser)
    const questionnaire = useQuestionnaire(questionnaireId)
    const submission = useSubmission(questionnaireId, submissionId)
    const defaultImages = {
        soleImage: defaultSoleImage,
        innerImage: defaultInnerImage,
        outerImage: defaultOuterImage,
    }

    const classes = useStyles()
    return !user || !questionnaire || !submission ? (
        <></>
    ) : (
        <Box className={classes.individualreport}>
            <Box className={classes.reporttop}>
                <Typography component={"h2"} variant={"h4"}>
                    {questionnaire.modelName}
                </Typography>
                {user.analyst && (
                    <Box className="btnwrap">
                        <Button
                            variant="outlined"
                            onClick={() =>
                                (window.location.href = `/admin/main/questionnaire/${questionnaire.id}/change/`)
                            }
                            size="small"
                            startIcon={<CreateIcon />}
                        >
                            설문지 수정
                        </Button>
                    </Box>
                )}
            </Box>
            <Box className={classes.boxwrap}>
                <Typography component={"h3"} variant={"body1"}>
                    기본 정보
                </Typography>
                <Box className={classes.boxdata}>
                    <Box>
                        <img src={questionnaire.outerImage || defaultOuterImage} alt="대표이미지" />
                    </Box>
                </Box>
                <Box className={classes.boxdata}>
                    <Box>
                        <TableContainer className={classes.reporttable} component={Paper}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <div>브랜드</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div>{questionnaire.name}</div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div>모델명</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div>{questionnaire.modelName}</div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <div>시즌</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div>{questionnaire.season}</div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div>카테고리</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <div>{questionnaire.category}</div>
                                        </TableCell>
                                    </TableRow>
                                    {questionnaire.typeName === "신발" && (
                                        <>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <div>무게</div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div>{questionnaire.weight} g</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div>Heel 높이</div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div>{questionnaire.heelHeight} mm</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <div>forefoot 높이</div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div>{questionnaire.forefootHeight} mm</div>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <div>drop 차이</div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div>{questionnaire.dropGap} mm</div>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <div>설문 안내</div>
                                        </TableCell>
                                        <TableCell align="center" colSpan={3}>
                                            <div>{questionnaire.guide}</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
            {questionnaire.dynamic_data.map((section) => (
                <Box key={section.id} className={classes.boxwrap}>
                    <Typography component={"h3"} variant={"body1"}>
                        {section.name}
                    </Typography>
                    {section.data.map((item, num) => {
                        const key = `${section.section}:${num + 1}`
                        const i = item as DynamicItem

                        if (section.section !== "B") {
                            return (
                                <Box key={num} className={classes.boxdata}>
                                    <Typography variant={"body2"}>{i.question}</Typography>
                                    <Box>
                                        <LikertScaleSeven
                                            value={submission.payload[key].scale}
                                            onChange={() => null}
                                            labels={[i.min_text, i.max_text]}
                                            disabled={true}
                                            isFitting={section.section === 'A' ? true : false}
                                        />
                                        {submission.payload[key].text && (
                                            <>
                                                <Typography className={classes.reson}>
                                                    이유
                                                </Typography>
                                                <Typography>
                                                    {submission.payload[key].text}
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            )
                        } else {
                            const images = [
                                {
                                    key: "outerImage",
                                    label: "이미지1",
                                },
                                {
                                    key: "innerImage",
                                    label: "이미지2",
                                },
                                {
                                    key: "soleImage",
                                    label: "이미지3",
                                },
                            ]
                            return (
                                <Box key={num} className={`${classes.boxdata} divide`}>
                                    {images.map((image) => (
                                        <Box key={image.label}>
                                            <MarkableImage
                                                imageSrc={
                                                    (questionnaire as any)[image.key] ||
                                                    (defaultImages as any)[image.key]
                                                }
                                                label={image.label}
                                                markers={submission.payload[key][image.key]}
                                                onChange={() => null}
                                                disabled={true}
                                            />
                                            <ul className={classes.textlist}>
                                                {submission.payload[key][image.key].map(
                                                    (m: any, index: number) => (
                                                        <li key={index}>
                                                            <span>{index + 1}</span>
                                                            <Typography>{m.text}</Typography>
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </Box>
                                    ))}
                                </Box>
                            )
                        }
                    })}
                </Box>
            ))}
            <Box className={classes.boxwrap}>
                <Typography component={"h3"} variant={"body1"}>
                    설문 관련 이미지
                </Typography>
                <Box className={classes.boxdata}>
                    <Typography variant={"body2"}>활동 인증 스크린샷</Typography>
                    <ul className={classes.imagelist}>
                        {submission.proofImages.map((image) => (
                            <li key={image.key}>
                                <img src={image.url} alt="" />
                            </li>
                        ))}
                    </ul>
                    <Typography variant={"body2"}>제품의 손상정도 이미지</Typography>
                    <ul className={classes.imagelist}>
                        {submission.usageImages.map((image) => (
                            <li key={image.key}>
                                <img src={image.url} alt="" />
                            </li>
                        ))}
                    </ul>
                </Box>
            </Box>
            {questionnaire.type === 1 && (
                <Box className={classes.boxwrap}>
                    <Typography component={"h3"} variant={"body1"}>
                        응답자 프로파일
                    </Typography>
                    <Box className={classes.boxdata}>
                        <Box>
                            <TableContainer
                                className={`${classes.reporttable} unit`}
                                component={Paper}
                            >
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>출생년도</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.birthyear}{" "}
                                                    <span>년</span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>신장</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.height}{" "}
                                                    <span>cm</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>체중</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.weight}{" "}
                                                    <span>kg</span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>신발 사이즈</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.shoesSize}{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>상의 사이즈</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>{submission.respondentProfile.topSize} </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>하의 사이즈</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.bottomSize}{" "}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>활동빈도</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.activityFreq}{" "}
                                                    <span>회/주</span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>활동거리</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.activityRange}{" "}
                                                    <span>km/주</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>왼쪽 발 길이</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.leftFootLength}{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>오른쪽 발 길이</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.rightFootLength}{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>왼쪽 발볼 둘레</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {
                                                        submission.respondentProfile
                                                            .leftFootBallCircumference
                                                    }{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>오른쪽 발볼 둘레</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {
                                                        submission.respondentProfile
                                                            .rightFootBallCircumference
                                                    }{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>왼쪽 발볼 넓이</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.leftFootBallWidth}{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>오른쪽 발볼 넓이</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {
                                                        submission.respondentProfile
                                                            .rightFootBallWidth
                                                    }{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>왼쪽 발등 둘레</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {
                                                        submission.respondentProfile
                                                            .leftFootTopCircumference
                                                    }{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>오른쪽 발등 둘레</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {
                                                        submission.respondentProfile
                                                            .rightFootTopCircumference
                                                    }{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>왼쪽 발등 높이</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.leftFootTopHeight}{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>오른쪽 발등 높이</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {
                                                        submission.respondentProfile
                                                            .rightFootTopHeight
                                                    }{" "}
                                                    <span>mm</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>왼쪽 풋 사이즈</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.leftFootSize}{" "}
                                                    <span></span>
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>오른쪽 풋 사이즈</div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div>
                                                    {submission.respondentProfile.rightFootSize}{" "}
                                                    <span></span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            )}
            {questionnaire.type === 1 && (
                <Box className={classes.boxwrap}>
                    <Typography component={"h3"} variant={"body1"}>
                        테스트 프로파일
                    </Typography>
                    <Box className={classes.boxdata}>
                        <Box>
                            <TableContainer className={classes.reporttable} component={Paper}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>착용 시작일</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <div>
                                                    {formatDate(
                                                        submission.testProfile.wearStartedAt,
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>착용 종료일</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <div>
                                                    {formatDate(submission.testProfile.wearEndedAt)}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div>활동 장소</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <div>{submission.testProfile.wearPlace}</div>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <div>착용 거리</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <div>{submission.testProfile.wearDistance} km</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box className={classes.boxdata}>
                        <Typography variant={"body2"}>테스트 활동</Typography>
                        <Box>
                            <ul className={classes.listtype}>
                                <li>
                                    <p>걷기</p>
                                    <strong>
                                        {submission.testProfile.activityWalk}
                                        <span>%</span>
                                    </strong>
                                </li>
                                <li>
                                    <p>달리기</p>
                                    <strong>
                                        {submission.testProfile.activityRun}
                                        <span>%</span>
                                    </strong>
                                </li>
                                {submission.testProfile.activityMiscText !== "" && (
                                    <li>
                                        <p>{submission.testProfile.activityMiscText}</p>
                                        <strong>
                                            {submission.testProfile.activityMisc}
                                            <span>%</span>
                                        </strong>
                                    </li>
                                )}
                            </ul>
                        </Box>
                    </Box>
                    <Box className={classes.boxdata}>
                        <Typography variant={"body2"}>테스트 환경</Typography>
                        <Box>
                            <ul className={classes.listtype}>
                                <li>
                                    <p>아스팔트</p>
                                    <strong>
                                        {submission.testProfile.envAsphalt}
                                        <span>%</span>
                                    </strong>
                                </li>
                                <li>
                                    <p>트랙</p>
                                    <strong>
                                        {submission.testProfile.envTrack}
                                        <span>%</span>
                                    </strong>
                                </li>
                                <li>
                                    <p>암반</p>
                                    <strong>
                                        {submission.testProfile.envRock}
                                        <span>%</span>
                                    </strong>
                                </li>
                                <li>
                                    <p>흙길</p>
                                    <strong>
                                        {submission.testProfile.envDirt}
                                        <span>%</span>
                                    </strong>
                                </li>
                                <li>
                                    <p>숲길</p>
                                    <strong>
                                        {submission.testProfile.envForest}
                                        <span>%</span>
                                    </strong>
                                </li>
                                {submission.testProfile.envMiscText !== "" && (
                                    <li>
                                        <p>{submission.testProfile.envMiscText}</p>
                                        <strong>
                                            {submission.testProfile.envMisc}
                                            <span>%</span>
                                        </strong>
                                    </li>
                                )}
                            </ul>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

function useSubmission(qid: number, sid: number): Submission | null | undefined {
    const { data } = useSWR(["/api/questionnaires/{qid}/submissions/{sid}", qid, sid], (_, q, s) =>
        server.getSubmission(q, s),
    )
    if (data === undefined) return undefined
    return data
}

function useQuestionnaire(qid: number): QuestionnaireData | undefined {
    const { data } = useSWR(["/api/questionnaires/{qid}", qid], (_, q) =>
        server.getQuestionnaire(q),
    )
    return data
}