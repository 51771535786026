import QuestionnaireTextField from "./QuestionnaireTextField"
import { RespondentProfile as RespondentProfileData } from "../types"
// import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Theme, withStyles } from "@material-ui/core/styles"
import React from "react"

const useStyles = (_: Theme) => ({
    fieldWrapper: {
        padding: "10px 20px 25px",
    },
})

interface Props {
    classes: any
    profile: RespondentProfileData
    onChange: (profile: RespondentProfileData) => void
}

function RespondentProfile({ classes, profile, onChange }: Props): React.ReactElement {
    return <>
        <div className={classes.fieldWrapper}>
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="출생년도"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">년</InputAdornment>,
                }}
                placeholder="1900"
                value={profile.birthyear}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        birthyear: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
                required
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="신장"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="165"
                value={profile.height}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        height: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
                required
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="체중"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="60"
                value={profile.weight}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        weight: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
                required
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="신발 사이즈"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="250"
                value={profile.shoesSize}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        shoesSize: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
                required
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="상의 사이즈"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    inputProps: { min: 0 },
                }}
                placeholder="90"
                value={profile.topSize}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        topSize: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
                required
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="하의 사이즈"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    inputProps: { min: 0 },
                }}
                placeholder="28"
                value={profile.bottomSize}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        bottomSize: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
                required
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="활동빈도"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">회/주</InputAdornment>,
                    inputProps: { min: 1 },
                }}
                placeholder="1"
                value={profile.activityFreq}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        activityFreq: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
                required
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="활동거리"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">km/주</InputAdornment>,
                    inputProps: { min: 1 },
                }}
                placeholder="1"
                value={profile.activityRange}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        activityRange: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
                required
            />
        </div>
        <div className='divider'></div>
        <div className={classes.fieldWrapper}>
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="왼쪽 발 길이"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="250"
                value={profile.leftFootLength}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        leftFootLength: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="오른쪽 발 길이"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="250"
                value={profile.rightFootLength}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        rightFootLength: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="왼쪽 발볼 둘레"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="250"
                value={profile.leftFootBallCircumference}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        leftFootBallCircumference:
                            e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="오른쪽 발볼 둘레"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="250"
                value={profile.rightFootBallCircumference}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        rightFootBallCircumference:
                            e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="왼쪽 발볼 넓이"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="100"
                value={profile.leftFootBallWidth}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        leftFootBallWidth: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="오른쪽 발볼 넓이"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="102"
                value={profile.rightFootBallWidth}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        rightFootBallWidth: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="왼쪽 발등 둘레"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="250"
                value={profile.leftFootTopCircumference}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        leftFootTopCircumference: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="오른쪽 발등 둘레"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="250"
                value={profile.rightFootTopCircumference}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        rightFootTopCircumference:
                            e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="왼쪽 발등 높이"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="64"
                value={profile.leftFootTopHeight}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        leftFootTopHeight: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="number"
                fullWidth={true}
                label="오른쪽 발등 높이"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                    inputProps: { min: 0 },
                }}
                placeholder="66"
                value={profile.rightFootTopHeight}
                onChange={(e) =>
                    onChange({
                        ...profile,
                        rightFootTopHeight: e.target.value === "" ? NaN : +e.target.value,
                    })
                }
            />
            <QuestionnaireTextField
                type="string"
                fullWidth={true}
                label="왼쪽 풋 사이즈"
                InputLabelProps={{ shrink: true }}
                placeholder="250 2E"
                value={profile.leftFootSize}
                onChange={(e) => onChange({ ...profile, leftFootSize: e.target.value })}
            />
            <QuestionnaireTextField
                type="string"
                fullWidth={true}
                label="오른쪽 풋 사이즈"
                InputLabelProps={{ shrink: true }}
                placeholder="250 2E"
                value={profile.rightFootSize}
                onChange={(e) => onChange({ ...profile, rightFootSize: e.target.value })}
            />
        </div>
    </>
}

export default React.memo(withStyles(useStyles as any)(RespondentProfile))
