import Box from "@material-ui/core/Box"
import LinearProgress from "@material-ui/core/LinearProgress"
import { makeStyles, createStyles, withStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
// import FlagIcon from "@material-ui/icons/Flag"
import React, { useState, useEffect } from "react"

const useStyles = makeStyles({
    progresswrap: {
        position: "relative",
        width: "calc(100% - 4.375rem)",
        // marginBottom: "30px",
        "& .MuiSvgIcon-root": {
            position: "absolute",
            top: "-0.688rem",
            right: "-0.625rem",
        },
    },

    distance: {
        position: "absolute",
        top: "-1.375rem",
        width: "3.125rem",
        marginLeft: "-1.75rem",
        color: "#4FA9BA",
        fontSize: "0.875rem",
        fontWeight: 500,
        textAlign: "center",
        // "&.over": {
        //     color: "#d1131c",
        // },
        "& span": {
            fontWeight: 600,
            fontSize: "0.7rem",
        },
        "&:after": {
            content: '""',
            position: "absolute",
            top: "26.5px",
            left: "50%",
            transform: "translateX(-120%)",
            width: '9px', height: '9px',
            backgroundColor: "#fff",
            borderRadius: "1.125rem",
        }
    },

    totaldistance: {
        position: "absolute",
        top: "-0.188rem",
        right: "-4.38rem",
        width: "3.8rem",
        color: "#000",
        fontSize: "1rem",
        textAlign: "left",
        "&.complete": {
            // color: "#A7E521",
            fontWeight: 700,
        },
        "& span": {
            fontSize: "0.875rem",
        },
    },
})

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "1.125rem",
            borderRadius: "1.125rem",
            // backgroundColor: "#E2E9F0",
            backgroundColor: "#F4F4F4",
            // boxShadow: "2px 2px 2px rgba(0,0,0,0.15)",
        },
        bar: {
            borderRadius: "1.125rem",
            backgroundColor: "#4FA9BA",
            // backgroundColor: "#A7E521",
        },
    }),
)(LinearProgress)

interface Props {
    totalDistance: number
    distance: number
}

export default function ProgressDistance({ totalDistance, distance }: Props): React.ReactElement {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const value = (distance / totalDistance) * 100
        distance >= totalDistance ? setProgress(100) : setProgress(value)
    }, [distance, totalDistance])

    const classes = useStyles()
    return (
        <Box className={classes.progresswrap}>
            <BorderLinearProgress variant="determinate" value={progress} />
            <Typography
                className={`${classes.totaldistance} ${progress === 100 ? "complete" : ""}`}
            >{totalDistance}km</Typography>
            {/* <FlagIcon color="primary" /> */}
            {progress > 0 && distance !== totalDistance && (
                <Typography
                    className={`${classes.distance} ${distance > totalDistance ? "over" : ""}`}
                    style={{ left: `${progress}%` }}
                >
                    {progress.toFixed(2)}<span>%</span>
                    {/* {distance}<span> km</span> */}
                </Typography>
            )}
            {/* <Typography
                className={`${classes.totaldistance} ${progress === 100 ? "complete" : ""}`}
            >
                {totalDistance}
                <span> km</span>
            </Typography> */}
        </Box>
    )
}
