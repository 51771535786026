import { RootState } from "../app/store"
import { createSlice } from "@reduxjs/toolkit"

interface LoadingState {
    jobs: number
}

export const initialState: LoadingState = {
    jobs: 0,
}

export const loadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
        increase: (state) => {
            state.jobs++
        },
        decrease: (state) => {
            if (state.jobs === 0) throw new Error("cannot decrease jobs below zero")
            state.jobs--
        },
    },
})

export const { increase, decrease } = loadingSlice.actions

export const selectLoading = (state: RootState): boolean => state.loading.jobs > 0

export default loadingSlice.reducer
