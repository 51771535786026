import { RootState } from "../app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface percentageState {
    profileActive: boolean
    profileEnv: boolean
}

export const initialState: percentageState = {
    profileActive: false,
    profileEnv: false,
}

export const percentageSlice = createSlice({
    name: "percentage",
    initialState,
    reducers: {
        setPercentageActive: (state, action: PayloadAction<boolean>) => {
            state.profileActive = action.payload
        },
        setPercentageEnv: (state, action: PayloadAction<boolean>) => {
            state.profileEnv = action.payload
        },
    },
})

export const { setPercentageActive, setPercentageEnv } = percentageSlice.actions

export const selectPercentage = (state: RootState): boolean =>
    state.percentage.profileActive && state.percentage.profileEnv ? true : false
export default percentageSlice.reducer
