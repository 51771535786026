import { withStyles } from "@material-ui/core/styles"
import TextField, { StandardTextFieldProps } from "@material-ui/core/TextField"
import React from "react"

const CustomTextField = withStyles({
    root: {
        "& .MuiInputLabel-root": {
            marginTop: "1rem",
            lineHeight: "1.3rem",
            color: "#333",
        },
        "& .MuiInputBase-root": {
            marginTop: "1.25rem",
            border: "1px solid #ddd",
            padding: "4px 15px",
            borderRadius: "10px",
            fontSize: "0.875rem",
        },
        "& label + .MuiInputBase-root": {
            marginTop: "38px",
        },
        "& .MuiInputBase-input": {
            padding: "0.5rem 0",
            height: "1.625rem",
            // fontWeight: "500",
            fontSize: "1rem",
            lineHeight: "1.625rem",
        },
        "& .MuiInputBase-multiline": {
            padding: "0",
        },
        "& .MuiInput-underline:before": {
            borderBottom: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none",
        },
        "& .Mui-focused.MuiInput-underline:after": {
            borderBottom: "none",
        },
        "& .MuiInputLabel-shrink": {
            transform: "none",
            fontSize: "12px",
        },
        "& .Mui-required": {
            color: "#000",
            // fontWeight: "500",
        },
        "& .MuiFormLabel-asterisk": {
            color: "#45C1A0",
        }
    },
})(TextField)

function QuestionnaireTextField(props: StandardTextFieldProps) {
    if (props.type === "number") {
        const value = isNaN(props.value as number) ? "" : String(props.value)
        const newProps = { ...props, value }

        return <CustomTextField {...newProps} />
    } else {
        return <CustomTextField {...props} />
    }
}

export default React.memo(QuestionnaireTextField)
