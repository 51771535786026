import { KVList, Questionnaire, SubmissionRow } from "../../types"
import { makeStyles } from "@material-ui/core/styles"
import { ascending, scaleOrdinal, schemeTableau10 } from "d3"
import React from "react"

interface Props {
    questionnaires: Questionnaire[]
    data: KVList<SubmissionRow>[]
}

const useStyles = makeStyles({
    header: {
        height: "3rem",
    },

    plotcontainer: {
        display: "flex",
        padding: "1rem 0.5rem 0.5rem",
        backgroundColor: "#F4F4F4",
        gap: "0.8rem",
    },

    textlist: {
        padding: "0",
        margin: "0.625rem 0 0",

        "& li": {
            position: "relative",
            paddingLeft: "1.625rem",
            marginBottom: "0.313rem",
            lineHeight: "1.25rem",
            listStyle: "none",

            "&.empthyrow": {
                paddingLeft: "0",
                color: "#aaa",
            },

            "&:last-child": {
                marginBottom: "0",
            },
        },

        "& span": {
            position: "absolute",
            top: "0",
            left: "0",
            width: "1.25rem",
            height: "1.25rem",
            backgroundColor: "#f0f0f0",
            borderRadius: "50%",
            fontSize: "0.625rem",
            fontWeight: "bold",
            lineHeight: "1.25rem",
            textAlign: "center",
        },
    },

    commenttable: {
        width: "100%",
        borderCollapse: "collapse",
    },
})

export default function Comment({ questionnaires, data }: Props): React.ReactElement {
    const classes = useStyles()
    const color = scaleOrdinal<number, string>()
        .domain(questionnaires.map((q) => q.id))
        .range(schemeTableau10)

    return (
        <div className="tabcontent">
            <div className={classes.commenttable}>
                <div>
                    {data.map((datum) => {
                        const texts = datum.values
                            .map((d) => d.text)
                            .sort(ascending)
                            .filter((text) => text !== "")

                        const questionnaire = questionnaires.find(
                            (item) => item.id === Number(datum.key),
                        )

                        return (
                            <div key={datum.key} style={{ marginBottom: '15px' }}>
                                <div style={{ color: color(+datum.key), fontSize: '1.2rem' }}>
                                    {questionnaire?.modelName}
                                </div>
                                <div>
                                    {/* 요건 남겨두고 */}
                                    <ul className={classes.textlist}>
                                        {texts.length === 0 && (
                                            <li className="empthyrow">내용이 없습니다.</li>
                                        )}
                                        {texts.map((text: string, index: number) => {
                                            return (
                                                <li
                                                    key={`cmt${index}:${datum.values[index].key}`}
                                                    style={{ color: color(+datum.key) }}
                                                >
                                                    <span>{index + 1}</span>
                                                    {text}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}