import { Questionnaire, Submission, SubmissionRow, User, QuestionnaireType } from "./types"
import axios from "axios"
import { csvParse } from "d3"

export abstract class Server {
    private static server: Server

    static getServer(): Server {
        if (!this.server) {
            this.server = new RealServer()
        }
        return this.server
    }

    abstract getUser(): Promise<User | null>

    abstract getQuestionnaireTypes(): Promise<QuestionnaireType[]>

    abstract getQuestionnaires(tid: number, category: string): Promise<Questionnaire[]>
    
    abstract getAllQuestionnaires(page?: number): Promise<Questionnaire[]>

    abstract getFilterList(tid: number): Promise<Record<string, string[]>>

    abstract getQuestionnairesForAnalysis(qids: number[]): Promise<Questionnaire[]>

    abstract getSubmissionsForAnalysis(qids: number[]): Promise<SubmissionRow[]>

    abstract getQuestionnaire(qid: number): Promise<Questionnaire>

    abstract saveSubmission(submission: Submission): Promise<void>

    abstract savePermission(permission: string): Promise<void>

    abstract getSubmission(qid: number, sid?: number): Promise<Submission | null>
}

export class RealServer extends Server {
    async getUser(): Promise<User | null> {
        const res = await axios.get("/api/users/self")
        return res.data.loggedIn ? res.data.user : null
    }

    async getQuestionnaireTypes(): Promise<QuestionnaireType[]> {
        const res = await axios.get("/api/get-type")
        return res.data
    }

    async getQuestionnaires(tid: number, category: string): Promise<Questionnaire[]> {
        const res = await axios.get(`/api/questionnaires/type/${tid}${category}`)
        return res.data
    }

    async getAllQuestionnaires(page?: number): Promise<Questionnaire[]> {
        const res = page 
            ? await axios.get(`/api/questionnaires?page=${page}`) 
            : await axios.get(`/api/questionnaires`)
        return res.data
    }

    async getFilterList(tid: number): Promise<Record<string, string[]>> {
        const res = await axios.get(`/api/questionnaires-filter/${tid}`)
        return res.data
    }

    async getQuestionnairesForAnalysis(qids: number[]): Promise<Questionnaire[]> {
        const res = await axios.get(`/api/analysis/questionnaires?qids=${qids.join(",")}`)
        return res.data
    }

    async getSubmissionsForAnalysis(qids: number[]): Promise<SubmissionRow[]> {
        const res = await axios.get(`/api/analysis/submissions?qids=${qids.join(",")}`)
        return csvParse(res.data).map((raw: any) => {
            return {
                ...raw,
                questionnaireId: +raw.questionnaireId,
                submissionId: +raw.submissionId,
                value: raw.value,
                text: raw.text || "",
                like: raw.like,
            } as SubmissionRow
        })
    }

    async getQuestionnaire(qid: number): Promise<Questionnaire> {
        const res = await axios.get(`/api/questionnaires/${qid}`)
        return res.data
    }

    async saveSubmission(submission: Submission): Promise<void> {
        const raw = {
            ...submission,
            uploads: {},
            testProfile: {
                ...submission.testProfile,
                wearStartedAt: new Date(submission.testProfile.wearStartedAt)
                    .toISOString()
                    .split("T")[0],
                wearEndedAt: new Date(submission.testProfile.wearEndedAt)
                    .toISOString()
                    .split("T")[0],
            },
        }

        const formData = new FormData()
        formData.append("json", JSON.stringify(raw))
        Object.entries(submission.uploads).forEach(([key, files]) => {
            files.forEach((f, i) => {
                formData.append(`${key}:${i}`, f.file)
            })
        })
        const res = await axios.post(
            `/api/questionnaires/${submission.questionnaireId}/submission`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            },
        )
        return Object.keys(res.data).length === 0 ? null : res.data
    }

    async savePermission(permission: string): Promise<void> {
        const res = await axios.get(`/api/set-group/?group=${permission}`)

        return Object.keys(res.data).length === 0 ? null : res.data
    }

    async getSubmission(qid: number, sid?: number): Promise<Submission | null> {
        let res
        try {
            res = await axios.get(
                sid === undefined
                    ? `/api/questionnaires/${qid}/submission`
                    : `/api/questionnaires/${qid}/submissions/${sid}`,
            )
        } catch (e) {
            return null
        }

        const raw = res.data
        return {
            ...raw,
            testProfile: {
                ...raw.testProfile,
                wearStartedAt: +new Date(raw.testProfile.wearStartedAt),
                wearEndedAt: +new Date(raw.testProfile.wearEndedAt),
            },
        }
    }
}

// interface Data {
//     questionnaires: Questionnaire[]
//     submissions: Submission[]
//     user: User | null
//     submissionRows: SubmissionRow[]
// }

// export class FakeServer extends Server {
//     private static readonly DELAY: number = 500
//     private readonly _data: Data

//     constructor() {
//         super()

//         this._data =
//             process.env.NODE_ENV === "development"
//                 ? {
//                       user: {
//                           username: "user0",
//                           firstName: "뫄뫄",
//                           lastName: "김",
//                           respondentProfile: {
//                               birthyear: 1999,
//                               height: 170,
//                               weight: 60,
//                               leftFootLength: 239,
//                               rightFootLength: 238.7,
//                               leftFootBallCircumference: 245.3,
//                               rightFootBallCircumference: 250.3,
//                               leftFootBallWidth: 102.3,
//                               rightFootBallWidth: 105,
//                               leftFootTopCircumference: 242.7,
//                               rightFootTopCircumference: 247.3,
//                               leftFootTopHeight: 64.9,
//                               rightFootTopHeight: 66.4,
//                               leftFootSize: "240 2E",
//                               rightFootSize: "240 3E",
//                               shoesSize: 240,
//                               activityFreq: 1,
//                               activityRange: 1,
//                           },
//                           tester: false,
//                           analyst: true,
//                           createdAt: +new Date(2020, 0, 1),
//                       },
//                       questionnaires: [
//                           {
//                               id: 0,
//                               name: "a 설문지",
//                               modelName: "탱크 커맨더 2 로우 GREY",
//                               brief: "1회차",
//                               guide: "...설문 안내 문구가 들어가는 곳...",
//                               season: "21SS",
//                               category: "경등산화",
//                               weight: 200,
//                               heelHeight: 40,
//                               forefootHeight: 30,
//                               dropGap: 10,
//                               submitted: true,
//                               soleImage: null,
//                               outerImage: null,
//                               innerImage: null,
//                               hidden: false,
//                               closed: false,
//                               goalDistance: 200,
//                               accumulatedDistance: 204,
//                               tags: ["A", "B"],
//                               createdAt: +new Date(2020, 0, 1),
//                               updatedAt: +new Date(2020, 0, 1),
//                           },
//                           {
//                               id: 1,
//                               name: "b 설문지",
//                               modelName: "앵글 NAVY",
//                               brief: "1회차",
//                               guide: "...설문 안내 문구가 들어가는 곳...",
//                               season: "21SS",
//                               category: "경등산화",
//                               weight: 200,
//                               heelHeight: 40,
//                               forefootHeight: 30,
//                               dropGap: 10,
//                               soleImage: null,
//                               outerImage: null,
//                               innerImage: null,
//                               submitted: false,
//                               hidden: false,
//                               closed: false,
//                               goalDistance: 100,
//                               accumulatedDistance: 20,
//                               tags: ["A", "C"],
//                               createdAt: +new Date(2020, 1, 2),
//                               updatedAt: +new Date(2020, 1, 3),
//                           },
//                           {
//                               id: 2,
//                               name: "c 설문지",
//                               modelName: "탱크 커맨더 2 로우 GREY",
//                               brief: "2회차",
//                               guide: "...설문 안내 문구가 들어가는 곳...",
//                               season: "21SS",
//                               category: "경등산화",
//                               weight: 200,
//                               heelHeight: 40,
//                               forefootHeight: 30,
//                               dropGap: 10,
//                               soleImage: null,
//                               outerImage: null,
//                               innerImage: null,
//                               submitted: false,
//                               hidden: false,
//                               closed: true,
//                               goalDistance: 100,
//                               accumulatedDistance: 100,
//                               tags: [],
//                               createdAt: +new Date(2020, 1, 3),
//                               updatedAt: +new Date(2020, 1, 3),
//                           },
//                           {
//                               id: 3,
//                               name: "d 설문지",
//                               modelName: "탱크 커맨더 2 로우 GREY",
//                               brief: "3회차",
//                               guide: "...설문 안내 문구가 들어가는 곳...",
//                               season: "21SS",
//                               category: "경등산화",
//                               weight: 200,
//                               heelHeight: 40,
//                               forefootHeight: 30,
//                               dropGap: 10,
//                               soleImage: null,
//                               outerImage: null,
//                               innerImage: null,
//                               submitted: false,
//                               hidden: false,
//                               closed: false,
//                               goalDistance: 100,
//                               accumulatedDistance: 100,
//                               tags: [],
//                               createdAt: +new Date(2020, 1, 4),
//                               updatedAt: +new Date(2020, 1, 4),
//                           },
//                       ],
//                       submissions: [
//                           {
//                               questionnaireId: 0,
//                               username: "user0",
//                               respondentProfile: {
//                                   birthyear: 1999,
//                                   height: 170,
//                                   weight: 60,
//                                   leftFootLength: 239,
//                                   rightFootLength: 238.7,
//                                   leftFootBallCircumference: 245.3,
//                                   rightFootBallCircumference: 250.3,
//                                   leftFootBallWidth: 102.3,
//                                   rightFootBallWidth: 105,
//                                   leftFootTopCircumference: 242.7,
//                                   rightFootTopCircumference: 247.3,
//                                   leftFootTopHeight: 64.9,
//                                   rightFootTopHeight: 66.4,
//                                   leftFootSize: "240 2E",
//                                   rightFootSize: "240 3E",
//                                   shoesSize: 240,
//                                   activityFreq: 1,
//                                   activityRange: 1,
//                               },
//                               testProfile: DEFAULT_TEST_PROFILE,
//                               uploads: {},
//                               proofImages: [],
//                               usageImages: [],
//                               payload: {
//                                   "A:1": { scale: 0, text: "" },
//                                   "A:2": { scale: 7, text: "이유" },
//                                   "A:3": { scale: 5, text: "" },
//                                   "A:4": { scale: 2, text: "" },
//                                   "B:1": {
//                                       soleImage: [
//                                           {
//                                               text: "아픕니다.",
//                                               x: 0.4096638655462185,
//                                               y: 0.7151832036316472,
//                                           },
//                                       ],
//                                       outerImage: [
//                                           {
//                                               text: "아픕니다.",
//                                               x: 0.4096638655462185,
//                                               y: 0.7151832036316472,
//                                           },
//                                           {
//                                               text: "그냥 아픕니다.",
//                                               x: 0.6987394957983193,
//                                               y: 0.1548719195849546,
//                                           },
//                                       ],
//                                       innerImage: [
//                                           {
//                                               text: "아픕니다.",
//                                               x: 0.4096638655462185,
//                                               y: 0.7151832036316472,
//                                           },
//                                       ],
//                                   },
//                                   "C:1": { scale: 3, text: "" },
//                                   "C:2": { scale: 1, text: "" },
//                                   "C:3": { scale: 2, text: "" },
//                                   "C:4": { scale: 3, text: "" },
//                                   "C:5": { scale: 4, text: "이유" },
//                                   "C:6": { scale: 6, text: "" },
//                                   "C:7": { scale: 7, text: "" },
//                                   "C:8": { scale: 1, text: "이유" },
//                                   "C:9": { scale: 3, text: "" },
//                                   "D:1": { scale: 2, text: "" },
//                                   "D:2": { scale: 4, text: "" },
//                               },
//                           },
//                           {
//                               questionnaireId: 1,
//                               username: "user0",
//                               respondentProfile: {
//                                   birthyear: 2016,
//                                   height: 160,
//                                   weight: 70,
//                                   leftFootLength: 239,
//                                   rightFootLength: 238.7,
//                                   leftFootBallCircumference: 245.3,
//                                   rightFootBallCircumference: 250.3,
//                                   leftFootBallWidth: 102.3,
//                                   rightFootBallWidth: 105,
//                                   leftFootTopCircumference: 242.7,
//                                   rightFootTopCircumference: 247.3,
//                                   leftFootTopHeight: 64.9,
//                                   rightFootTopHeight: 66.4,
//                                   leftFootSize: "240 2E",
//                                   rightFootSize: "240 3E",
//                                   shoesSize: 240,
//                                   activityFreq: 1,
//                                   activityRange: 1,
//                               },
//                               testProfile: DEFAULT_TEST_PROFILE,
//                               uploads: {},
//                               proofImages: [],
//                               usageImages: [],
//                               payload: {
//                                   "A:1": { scale: 2, text: "이유" },
//                                   "A:2": { scale: 3, text: "" },
//                                   "A:3": { scale: 3, text: "" },
//                                   "A:4": { scale: 4, text: "" },
//                                   "B:1": {
//                                       soleImage: [
//                                           {
//                                               text: "얘도 아픕니다.",
//                                               x: 0.3407563025210084,
//                                               y: 0.35833060288335516,
//                                           },
//                                           {
//                                               text: "엄청 아프네요.",
//                                               x: 0.07436974789915966,
//                                               y: 0.6021051769331586,
//                                           },
//                                       ],
//                                       outerImage: [
//                                           {
//                                               text: "여기가 아픕니다.",
//                                               x: 0.36512605042016805,
//                                               y: 0.6671935797665369,
//                                           },
//                                       ],
//                                       innerImage: [
//                                           {
//                                               text: "얘도 아픕니다.",
//                                               x: 0.3407563025210084,
//                                               y: 0.35833060288335516,
//                                           },
//                                           {
//                                               text: "엄청 아프네요.",
//                                               x: 0.07436974789915966,
//                                               y: 0.6021051769331586,
//                                           },
//                                       ],
//                                   },
//                                   "C:1": { scale: 1, text: "" },
//                                   "C:2": { scale: 2, text: "" },
//                                   "C:3": { scale: 6, text: "" },
//                                   "C:4": { scale: 7, text: "이유" },
//                                   "C:5": { scale: 7, text: "" },
//                                   "C:6": { scale: 1, text: "" },
//                                   "C:7": { scale: 2, text: "" },
//                                   "C:8": { scale: 6, text: "" },
//                                   "C:9": { scale: 7, text: "이유" },
//                                   "D:1": { scale: 3, text: "" },
//                                   "D:2": { scale: 4, text: "" },
//                               },
//                           },
//                           {
//                               questionnaireId: 2,
//                               username: "user0",
//                               respondentProfile: {
//                                   birthyear: 1999,
//                                   height: 170,
//                                   weight: 60,
//                                   leftFootLength: 239,
//                                   rightFootLength: 238.7,
//                                   leftFootBallCircumference: 245.3,
//                                   rightFootBallCircumference: 250.3,
//                                   leftFootBallWidth: 102.3,
//                                   rightFootBallWidth: 105,
//                                   leftFootTopCircumference: 242.7,
//                                   rightFootTopCircumference: 247.3,
//                                   leftFootTopHeight: 64.9,
//                                   rightFootTopHeight: 66.4,
//                                   leftFootSize: "240 2E",
//                                   rightFootSize: "240 3E",
//                                   shoesSize: 240,
//                                   activityFreq: 1,
//                                   activityRange: 1,
//                               },
//                               testProfile: DEFAULT_TEST_PROFILE,
//                               uploads: {},
//                               proofImages: [],
//                               usageImages: [],
//                               payload: {
//                                   "A:1": { scale: 2, text: "" },
//                                   "A:2": { scale: 4, text: "이유" },
//                                   "A:3": { scale: 2, text: "" },
//                                   "A:4": { scale: 7, text: "" },
//                                   "B:1": {
//                                       soleImage: [
//                                           {
//                                               text: "얘도 아픕니다.",
//                                               x: 0.3407563025210084,
//                                               y: 0.35833060288335516,
//                                           },
//                                           {
//                                               text: "엄청 아프네요.",
//                                               x: 0.07436974789915966,
//                                               y: 0.6021051769331586,
//                                           },
//                                       ],
//                                       outerImage: [
//                                           {
//                                               text: "여기가 아픕니다.",
//                                               x: 0.36512605042016805,
//                                               y: 0.6671935797665369,
//                                           },
//                                       ],
//                                       innerImage: [
//                                           {
//                                               text: "얘도 아픕니다.",
//                                               x: 0.3407563025210084,
//                                               y: 0.35833060288335516,
//                                           },
//                                           {
//                                               text: "엄청 아프네요.",
//                                               x: 0.07436974789915966,
//                                               y: 0.6021051769331586,
//                                           },
//                                       ],
//                                   },
//                                   "C:1": { scale: 1, text: "" },
//                                   "C:2": { scale: 3, text: "" },
//                                   "C:3": { scale: 5, text: "" },
//                                   "C:4": { scale: 6, text: "" },
//                                   "C:5": { scale: 7, text: "이유" },
//                                   "C:6": { scale: 5, text: "" },
//                                   "C:7": { scale: 4, text: "" },
//                                   "C:8": { scale: 4, text: "" },
//                                   "C:9": { scale: 1, text: "이유" },
//                                   "D:1": { scale: 2, text: "" },
//                                   "D:2": { scale: 3, text: "" },
//                               },
//                           },
//                       ],
//                       submissionRows: [
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "respondent",
//                               key: "birthyear",
//                               value: "1900",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "respondent",
//                               key: "height",
//                               value: "179",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "respondent",
//                               key: "weight",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "respondent",
//                               key: "size",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "respondent",
//                               key: "activityFreq",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "respondent",
//                               key: "activityRange",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "wearDistance",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "activityWalk",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "activityRun",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "activityMisc",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "envAsphalt",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "envTrack",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "envRock",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "envDirt",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "envForest",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "test",
//                               key: "envMisc",
//                               value: "69",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "A:1",
//                               value: "6",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "A:2",
//                               value: "5",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "A:3",
//                               value: "4",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "A:4",
//                               value: "3",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "B:1:soleImage:0",
//                               value: "0.6195652173913043, 0.23319376871257486",
//                               text: "이유입니다.",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:1",
//                               value: "2",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:2",
//                               value: "1",
//                               text: "2",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user1",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:2",
//                               value: "1",
//                               text: "2",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:3",
//                               value: "6",
//                               text: "텍스트 입니다.",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user1",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:3",
//                               value: "4",
//                               text: "텍스트 입니다2.",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:4",
//                               value: "6",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:5",
//                               value: "6",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:6",
//                               value: "6",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:7",
//                               value: "6",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:8",
//                               value: "5",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "C:9",
//                               value: "3",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "D:1",
//                               value: "2",
//                               text: "",
//                           },
//                           {
//                               questionnaireId: "1",
//                               submissionId: "1",
//                               username: "user0",
//                               createdAt: 1602656948657,
//                               updatedAt: 1602656948671,
//                               category: "payload",
//                               key: "D:2",
//                               value: "5",
//                               text: "",
//                           },
//                       ],
//                   }
//                 : {
//                       user: null,
//                       questionnaires: [],
//                       submissions: [],
//                       submissionRows: [],
//                   }
//     }

//     setUser(user: User): void {
//         this._data.user = user
//     }

//     async getUser(): Promise<User | null> {
//         await this.sleep(FakeServer.DELAY)
//         return this._data.user
//     }

//     setSaveQuestionnaires(questionnaires: Questionnaire[]): void {
//         this._data.questionnaires = questionnaires
//     }

//     async getQuestionnaires(): Promise<Questionnaire[]> {
//         await this.sleep(FakeServer.DELAY)
//         return this._data.questionnaires
//     }

//     async getQuestionnairesForAnalysis(qids: number[]): Promise<Questionnaire[]> {
//         await this.sleep(FakeServer.DELAY)
//         return this._data.questionnaires.filter((q) => qids.indexOf(q.id) !== -1)
//     }

//     async getSubmissionsForAnalysis(qids: number[]): Promise<SubmissionRow[]> {
//         await this.sleep(FakeServer.DELAY)
//         return this._data.submissionRows
//     }

//     async getQuestionnaire(qid: number): Promise<Questionnaire> {
//         await this.sleep(FakeServer.DELAY)

//         const questionnaire = this._data.questionnaires.find((q) => q.id === qid)
//         if (questionnaire === undefined) throw new Error(`Unknown id: ${qid}`)

//         return questionnaire
//     }

//     async saveSubmission(submission: Submission): Promise<void> {
//         await this.sleep(FakeServer.DELAY)

//         if (!this._data.questionnaires.find((q) => q.id === submission.questionnaireId))
//             throw new Error(`invalid questionnaireId: ${submission.questionnaireId}`)
//         this._data.submissions.push(submission)
//     }

//     async getSubmission(qid: number, sid?: number): Promise<Submission | null> {
//         const user = await this.getUser()
//         if (!user || (user.tester && sid !== undefined)) return null

//         const submission = this._data.submissions.find((s) => s.questionnaireId === qid)
//         return submission || null
//     }

//     private async sleep(delay: number): Promise<void> {
//         return new Promise((resolve) => setTimeout(resolve, delay))
//     }
// }
