import catIco1 from "../../assets/images/cat_ico_1.png"
import catIco2 from "../../assets/images/cat_ico_2.png"
import catIco3 from "../../assets/images/cat_ico_3.png"
import catIco4 from "../../assets/images/cat_ico_4.png"
import catIco5 from "../../assets/images/cat_ico_5.png"
import catIco6 from "../../assets/images/cat_ico_6.png"
import catIco7 from "../../assets/images/cat_ico_7.png"
import catIco8 from "../../assets/images/cat_ico_8.png"
import catIco9 from "../../assets/images/cat_ico_9.png"
import iconExpandMore from '../../assets/images/expand_more.png'
import { Server } from "../../server"
import { QuestionnaireType } from "../../types"
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { Link } from "react-router-dom"
import useSWR from "swr"

const icoImages = [
    catIco1,
    catIco2,
    catIco3,
    catIco4,
    catIco5,
    catIco6,
    catIco7,
    catIco8,
    catIco9,
]

const server = Server.getServer()

function useQuestionnaireTypes(): QuestionnaireType[] | undefined {
  const { data } = useSWR("/api/get-type/", () => server.getQuestionnaireTypes())
  return data
}

const useStyles = makeStyles({
    bottomBtn: {
        position: 'fixed',
        top: 'auto', bottom: 0,
        width: '100%',
        padding: '24px',
        backgroundColor: "#FFF",
        borderRadius: '30px 30px 0 0',
        color: '#999999',
    },
    listWrapper: {
        textAlign: 'center',
        '& .MuiBackdrop-root': {
            background: 'none',
        },
        '& .MuiDrawer-paper': {
            borderRadius: '30px 30px 0 0',
        }
    },

    list: {
        width: 'auto',
        height: '100%',
        overflowY: 'scroll',
        "& a": {
            display: "flex",
            textDecoration: "none",
            color: "#000",
        },
    },
    listTitle: {
        color: "#000 !important",
        paddingTop: '1.6rem',
        paddingBottom: '.625rem',
    },
    listIcon: {
        width: '4.1875rem', height: '4.1875rem',
        margin: '.25rem 1.25rem .25rem .25rem',
        borderRadius: '20px',
        fontSize: '1.125rem',
        "&:nth-child(1)::before": {
            backgroundColor: "#ADD83A",
        },
        "&:nth-child(2)::before": {
            backgroundColor: "#F49849",
        },
        "&:nth-child(3)::before": {
            backgroundColor: "#68C8EB",
        },
        "&:nth-child(-n+3) p": {
            color: "#FFF",
        },
        "&::before": {
            content: "''",
            position: "absolute",
            left: 0, right: 0, top: "4.1875rem", bottom: "0",
            width: "100%",
            // boxShadow: "0px 20px 20px 20px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            borderRadius: "1.875rem",
        },
    },

    drawerPaper: {
        width: 'auto',
        height: "calc(100vh - 72px)",
        border: 'none',
        borderRadius: '30px 30px 0 0',
    },
})

export default function InfoDrawer(): React.ReactElement {
    const classes = useStyles()
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    const toggleDrawer = (anchor: string, open: boolean) => (event: any) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return
        }

        document.getElementById('dim')?.classList.toggle('show')
        setState({ ...state, [anchor]: open })
    }

    const list = (anchor: any) => {
      const questionnaireTypes = useQuestionnaireTypes()
      if (questionnaireTypes) {
        return (
            <div
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
            >
                <Button className={classes.listTitle} disabled>
                    제품 리스트 전체보기
                    <img src={iconExpandMore} style={{ paddingLeft: "7px" }} />
                </Button>
                <List className={classes.list}>
                    {questionnaireTypes && questionnaireTypes.map((questionnaireType, index) => (
                        <Link to={`/questionnaireList/${questionnaireType.id}`} key={questionnaireType.id}>
                            <ListItem button>
                                <ListItemAvatar>
                                    <Avatar className={classes.listIcon} alt="" src={icoImages[index]} />
                                </ListItemAvatar>
                                <ListItemText primary={questionnaireType.typeName} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
          </div>
        )
      }

      return <></>
    }

    return (
        <div>
            <Button className={classes.bottomBtn} onClick={toggleDrawer('bottom', true)}>
                제품 리스트 전체보기
                <img src={iconExpandMore} style={{ paddingLeft: "7px" }} />
            </Button>
            <Drawer
                className={classes.listWrapper}
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor={'bottom'}
                open={state['bottom']}
                onClose={toggleDrawer('bottom', false)}
            >
                {list('bottom')}
            </Drawer>
        </div>
    )
}
