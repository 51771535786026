import { RootState } from "../app/store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface FlashMessageStateProps {
    message: string | null
}

export const initialState: FlashMessageStateProps = {
    message: null,
}

export const flashMessageSlice = createSlice({
    name: "flashMessage",
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<string | null>) => {
            state.message = action.payload
        },
    },
})

export const { setMessage } = flashMessageSlice.actions

export const selectFlashMessage = (state: RootState): string | null => state.flashMessage.message

export default flashMessageSlice.reducer
