import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import React from "react"

interface Props {
    description: string
    onClose: () => void
    onConfirm: () => void
}

export default function ConfirmDialog({
    description,
    onClose,
    onConfirm,
}: Props): React.ReactElement {
    return (
        <Dialog
            open
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>취소</Button>
                <Button onClick={onConfirm} color="primary">
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    )
}
