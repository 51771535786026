import Intro from "../components/Intro"
// import Footer from "../components/layouts/Footer"
import Header from "../components/layouts/Header"
import InfoDrawer from "../components/layouts/IntroCategoryDrawer"
import NoPage from "../components/NoPage"
import QuestionnaireList from "../components/QuestionnaireList"
import { reload } from "../features/userSlice"
import ComparisonPage from "../pages/admin/Comparison"
import Individual from "../pages/admin/Individual"
import QuestionnaireDetail from "../pages/QuestionnaireDetail"
import Container from "@material-ui/core/Container"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import queryString from "query-string"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        inner: {
            minHeight: "calc(100vh - 70px)",
            padding: "76px 1.5rem 40px",
        },
        inner1 : {
            minHeight: "calc(100vh - 58px)",
            margin: "0 auto",
            padding: "0",
            marginTop: "58px",
        },
        inner2: {
            minHeight: "calc(100vh - 58px)",
        },
        subinner: {
            padding: "0",
        },
    }),
)

export default function AppRouter(): React.ReactElement {
    const classes = useStyles()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(reload())
    }, [dispatch])

    return (
        <Router>
            <Switch>
                <Route
                    path="/"
                    exact
                    render={(props) => {
                        window.location.href = "accounts/login"
                        return null
                    }}
                />
                <Route
                    path="/info"
                    exact
                    render={() => <>
                        <Header />
                        <Container id="container" maxWidth="sm" className={classes.inner}>
                            <Intro />
                        </Container>
                        <InfoDrawer />
                    </>}
                />
                <Route
                    path="/questionnaireList"
                    exact
                    render={(props) => {
                        const category = props.location.search || ""
                        return <>
                            <Header />
                            <Container id="container" maxWidth="sm" className={classes.inner1}>
                                <QuestionnaireList tid={0} category={category} />
                            </Container>
                        </>
                    }}
                />
                <Route
                    path="/questionnaireList/:tid"
                    exact
                    render={(props) => {
                        const category = props.location.search || ""
                        return <>
                            <Header />
                            <Container id="container" maxWidth="sm" className={classes.inner1}>
                                <QuestionnaireList tid={+props.match.params.tid} category={category} />
                            </Container>
                        </>
                    }}
                />
                <Route
                    path="/questionnaires/:id"
                    render={(props) => (
                        <Container id="container" maxWidth="sm" className={classes.subinner}>
                            <QuestionnaireDetail questionnaireId={+props.match.params.id} />
                        </Container>
                    )}
                />
                <Route
                    path="/comparison"
                    exact
                    render={(props) => {
                        const qs = queryString.parse(props.location.search || "")
                        const rawQids = (qs.qids as string) || ""
                        const qids: number[] = rawQids.split(",").map((qid: string) => +qid)
                        return <>
                            <Header />
                            <ComparisonPage qids={qids} />
                        </>
                    }}
                />
                <Route
                    path="/individual/:qid/:sid"
                    render={(props) => <>
                        <Header />
                        <Individual
                            questionnaireId={+props.match.params.qid}
                            submissionId={+props.match.params.sid}
                        />
                    </>}
                />
                <Route
                    exact
                    render={() => {
                        return <>
                            <Header />
                            <Container id="container" maxWidth="sm" className={classes.inner}>
                                <NoPage />
                            </Container>
                        </>
                    }}
                />
            </Switch>
            <div id="dim"></div>
        </Router>
    )
}
