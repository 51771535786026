import { DefinitionItemLikert, KVList, Questionnaire, SubmissionRow } from "../../types"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { ascending, max, min, quantile, scaleLinear, scaleOrdinal, schemeTableau10 } from "d3"
import React from "react"

interface Props {
    questionnaires: Questionnaire[]
    item: DefinitionItemLikert
    data: KVList<SubmissionRow>[]
}

const useStyles = makeStyles({
    header: {
        height: "3rem",
    },

    plotcontainer: {
        display: "flex",
        padding: "1rem 0.5rem 0.5rem",
        backgroundColor: "#F4F4F4",
        gap: "0.8rem",
    },

    textlist: {
        padding: "0",
        margin: "0.625rem 0 0",

        "& li": {
            position: "relative",
            paddingLeft: "1.625rem",
            marginBottom: "0.313rem",
            lineHeight: "1.25rem",
            listStyle: "none",

            "&:last-child": {
                marginBottom: "0",
            },
        },

        "& span": {
            position: "absolute",
            top: "0",
            left: "0",
            width: "1.25rem",
            height: "1.25rem",
            backgroundColor: "#f0f0f0",
            borderRadius: "50%",
            fontSize: "0.625rem",
            fontWeight: "bold",
            lineHeight: "1.25rem",
            textAlign: "center",
        },
    },
})

export default function ReportLikert({ questionnaires, item, data }: Props): React.ReactElement {
    const classes = useStyles()
    const color = scaleOrdinal<number, string>()
        .domain(questionnaires.map((q) => q.id))
        .range(schemeTableau10)
    const R = 3
    const width = 20
    const height = 80
    const scale = scaleLinear().domain([1, 7]).range([height, 0])

    return (
        <section className="subkey">
            <Typography component={"p"} variant={"body2"} className={classes.header}>
                {item.question}
            </Typography>
            <div className={classes.plotcontainer}>
                <svg key="axis" width={80} height={height}>
                    <g>
                        <path
                            stroke="#666"
                            strokeWidth={0.5}
                            fill="none"
                            d={`M65,1 L69,1 L69,${height - 1} L65,${height - 1}`}
                        />
                        <text
                            fill="#666"
                            alignmentBaseline="before-edge"
                            textAnchor="end"
                            transform="translate(63, 0)"
                            fontSize="11"
                        >
                            {item.labels[1] === '매우 조임' ? `3 (${item.labels[1]})` : `7 (${item.labels[1]})`}
                        </text>
                        <text
                            fill="#666"
                            alignmentBaseline="after-edge"
                            textAnchor="end"
                            transform={`translate(63, ${height})`}
                            fontSize="11"
                        >
                            {item.labels[0] === '매우 느슨' ? `-3 (${item.labels[0]})` : `1 (${item.labels[0]})`}
                        </text>
                    </g>
                </svg>

                {data.map((datum) => {
                    const values = datum.values.map((d) => Math.max(1, +d.value)).sort(ascending)

                    if (values.length > 2) {
                        // 최대값과 최소값 제외.
                        const minValue = Math.min(...values)
                        const maxValue = Math.max(...values)
                        values.splice(values.findIndex((item) => item === minValue), 1)
                        values.splice(values.findIndex((item) => item === maxValue), 1)
                    }

                    const q1 = quantile(values, 0.25) || values[0]
                    const q2 = quantile(values, 0.5) || values[0]
                    const q3 = quantile(values, 0.75) || values[0]
                    const iqr = q3 - q1
                    const fences = [q1 - 1.5 * iqr, q3 + 1.5 * iqr]
                    const boxes = [
                        [q1, q2],
                        [q2, q3],
                    ]
                    const whiskers = [
                        [q1, min(values.filter((d) => fences[0] >= d)) || q1],
                        [q3, max(values.filter((d) => fences[0] <= d)) || q3],
                    ]
                    const outliers = values.filter((d) => d < fences[0] || fences[1] < d)
                    return (
                        <div key={datum.key}>
                            <svg
                                className="questionnaireId"
                                width={width * 3}
                                height={height + R * 2}
                                shapeRendering="crispEdges"
                                style={{ color: color(+datum.key) }}
                            >
                                <g className="whiskers">
                                    {whiskers.map((w, i) => {
                                        const d = `
                                            M${width * 0.5},${scale(w[0])}
                                            L${width * 0.5},${scale(w[1])}
                                            M0,${scale(w[1])} L${width},${scale(w[1])}
                                        `
                                        return (
                                            <path
                                                key={i}
                                                strokeWidth={1}
                                                stroke="currentColor"
                                                d={d}
                                            />
                                        )
                                    })}
                                </g>
                                <g className="boxes">
                                    {boxes.map((b, i) => {
                                        return (
                                            <rect
                                                key={i}
                                                x={0}
                                                width={width}
                                                y={scale(b[1])}
                                                height={(scale(b[0]) || 0) - (scale(b[1]) || 0)}
                                                fill="currentColor"
                                            />
                                        )
                                    })}
                                </g>
                                <g className="med">
                                    <line
                                        stroke="currentColor"
                                        strokeWidth="1"
                                        x2={width}
                                        y1={(scale(q2) || 0) - 1}
                                        y2={(scale(q2) || 0) - 1}
                                    />
                                    <line
                                        stroke="#FFF"
                                        strokeWidth="1"
                                        x2={width}
                                        y1={scale(q2)}
                                        y2={scale(q2)}
                                    />
                                    <text
                                        fill={color(+datum.key)}
                                        alignmentBaseline="after-edge"
                                        textAnchor="start"
                                        transform={`translate(${width * 1.5}, ${
                                            (scale(q2) || 0) + 6
                                        })`}
                                        fontSize="11"
                                    >
                                        {q2}
                                    </text>
                                    <line
                                        stroke="currentColor"
                                        strokeWidth="1"
                                        x2={width}
                                        y1={(scale(q2) || 0) + 1}
                                        y2={(scale(q2) || 0) + 1}
                                    />
                                </g>
                                <g className="outliers">
                                    {outliers.map((o, i) => {
                                        return (
                                            <circle
                                                key={i}
                                                fill="currentColor"
                                                r={R}
                                                cx={width * 0.5}
                                                cy={scale(o)}
                                            />
                                        )
                                    })}
                                </g>
                            </svg>
                        </div>
                    )
                })}
            </div>
            {questionnaires.length === 1 &&
                data.map((datum) => {
                    const texts = datum.values
                        .map((d) => d.text)
                        .sort(ascending)
                        .filter((text) => text !== "")
                    if (texts.length === 0) return false
                    return (
                        <ul key={datum.key} className={classes.textlist}>
                            {texts.map((text: string, index: number) => (
                                <li key={`${index}:${datum.values[index].key}`}>
                                    <span>{index + 1}</span>
                                    {text}
                                </li>
                            ))}
                        </ul>
                    )
                })}
        </section>
    )
}
