import Comment from "./Comment"
import PrintComment from "./PrintComment"
import { KVList, Questionnaire, SubmissionRow, DynamicSection } from "../../types"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Collapse from "@material-ui/core/Collapse"
import { makeStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import { ascending } from "d3"
import React, { useState } from "react"

interface Props {
    questionnaires: Questionnaire[]
    payloads: KVList<KVList<SubmissionRow>>[]
    arrData: DynamicSection
    isCommentPrint: boolean
}

interface StyleProps {
    isCommentPrint: boolean;
}

const useStyles = makeStyles({
    commentwrap: {
        marginTop: "1.5rem",
        "@media print": {
            display: "none",
        },
    },
    printCommentwrap: {
        marginTop: "1.5rem",
        "@media not print": {
            display: "none",
        },
        "@media print": {
            display: (props: StyleProps) =>  props.isCommentPrint ? "block !important" : 'none !important'
        },  
    },
})

function a11yProps(index: number) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    }
}

export default function ReportComment({
    arrData,
    payloads,
    questionnaires,
    isCommentPrint,
}: Props): React.ReactElement {
    const styleProps = {isCommentPrint}
    const classes = useStyles(styleProps)
    const [value, setValue] = useState(0)
    const [checked, setChecked] = useState(true)

    const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
        setValue(newValue)
    }

    const handleCheckChange = () => {
        setChecked((prev) => !prev)
    }

    return (
        <>
            <div className={classes.commentwrap}>
                <Button
                    endIcon={checked ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    onClick={handleCheckChange}
                >
                    코멘트 {checked ? "닫기" : "열기"}
                </Button>
                <Collapse in={checked}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {arrData.data.map((item, idx) => {
                                return <Tab key={idx} label={item.question} {...a11yProps(idx)} />
                            })}
                        </Tabs>
                    </AppBar>
                    {arrData.data.map((item, idx) => {
                        if (arrData.section != "B") {
                            const key = String(idx + 1)
                            const filterKey = `${arrData.section}:${key}`
                            const data = payloads.find((p) => p.key === filterKey)!.values
                            data.sort((a, b) => ascending(+a.key, +b.key))
                            // console.log("B아님", data)

                            return (
                                <div key={idx} hidden={value !== idx} role="tabpanel">
                                    <Comment questionnaires={questionnaires} data={data} />
                                </div>
                            )
                        }
                    })}
                </Collapse>
            </div>
            <div className={classes.printCommentwrap}>
                {arrData.data.map((item, key) => {
                    return (
                        <div key={key}>
                            <div style={{ fontSize: '1.4rem', fontWeight: '600', padding: '10px 0' }}>{item.question}</div>
                            <div>
                            {arrData.data.map((item, idx) => {
                                if (arrData.section != "B") {
                                    const key = String(idx + 1)
                                    const filterKey = `${arrData.section}:${key}`
                                    const data = payloads.find((p) => p.key === filterKey)!.values
                                    data.sort((a, b) => ascending(+a.key, +b.key))
                                    // console.log("B아님", data)

                                    return (
                                        <div key={idx} hidden={value !== idx} role="tabpanel" style={{ paddingLeft: '10px' }}>
                                            <PrintComment questionnaires={questionnaires} data={data} />
                                        </div>
                                    )
                                }
                            })}
                            </div>
                        </div>)
                    })}
            </div>
        </>
    )
}