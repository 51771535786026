import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import { withStyles } from "@material-ui/core/styles"
import React from "react"

const CustomLoadingWrap = withStyles({
    root: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        // 1600? https://material-ui.com/customization/z-index/
        zIndex: 1600,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
})(Box)

const RADIUS = 30

const CustomLoadingSpinner = withStyles({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: `-${RADIUS}px`,
        marginLeft: `-${RADIUS}px`,
    },
})(CircularProgress)

export default function LoadingSpinner(): React.ReactElement {
    return (
        <CustomLoadingWrap>
            <CustomLoadingSpinner size={RADIUS * 2} />
        </CustomLoadingWrap>
    )
}
