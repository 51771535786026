import BtnX from '../assets/images/btn_x.svg'
import { TextField } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React, { useState } from "react"

interface Props {
    onConfirm: (text: string, like: boolean) => void
    onCancel: () => void
    onDelete: () => void
    markerText: string
    markerLike: boolean
    src: string
}

const CustomDialog = withStyles({
    root: {
        "& .MuiDialog-paper": {
            borderRadius: '15px',
        },
        '& .content-wrapper': {
            padding: '25px',
        },
        '& .title-wrapper': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& .content-block': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '10px 0'
        },
        '& .img-wrapper': {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px',
        },
        '& .img-wrapper img': {
            width: '80%',
        },
        '& .liker-wrapper': {
            width: '100%',
            display: 'flex',
            gap: '10px',
        },
        '& .text-area': {
            width: '100%',
        },
        '& .MuiInputBase-multiline': {
            border: '1px solid #DDDDDD',
            borderRadius: '10px',
        },
        '& .MuiInputBase-multiline .Mui-Focused': {
            border: '1px solid #b5b5b5 !important',
            borderRadius: '20px'
        },
        '& .confirm-btn-wrapper': {
            width: '100%',
            height: '60px',

            '& button': {
                width: '50%',
                minHeight: '60px',
                fontSize: '16px',

                '&:first-child': {
                    backgroundColor: '#F4F4F4',
                    color: '#999999',
                },
                '&:last-child': {
                    backgroundColor: '#EF6156',
                    color: '#fff',
                }
            },
        },


        "& .dialogTitle": {
            padding: "0.625rem 1.25rem",
            backgroundColor: "#f1f1f1",
        },
        "& .dialogTitle h2": {
            fontSize: "1rem",
        },
        "& .dialogTitle button": {
            position: "absolute",
            top: "0",
            right: "0.25rem",
        },
        "& .dialogContent": {
            padding: "1.25rem 1.25rem 1.625rem",
        },
        "& .dialogContent p": {
            marginBottom: "1rem",
            fontSize: "0.875rem",
            textAlign: "center",
        },
    },
})(Dialog)

interface SelectorStyleProps {
    selected: boolean
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '12px',
        borderRadius: '10px',
        color: (props: SelectorStyleProps) => props.selected ? '#fff' : '#888888',
        fontWeight: (props: SelectorStyleProps) => props.selected ? 600 : 400,
        backgroundColor: (props: SelectorStyleProps) => props.selected ? 'rgb(106, 190, 162)' : 'rgb(238, 238, 238)',
        // border: (props: SelectorStyleProps) => props.selected ? '2px solid black' : '1px solid #DDDDDD',
    }
})

interface SelectorProps {
    text: string
    selected: boolean
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Selector = ({text, selected, onClick}: SelectorProps) => {
    const classes = useStyles({selected})
    
    return (
        <button className={classes.root} onClick={onClick}>
            {text}
        </button>
    )
}

export default function MarkerDialog(props: Props): React.ReactElement {
    const [text, setText] = useState<string>(props.markerText ? props.markerText : "")
    const [like, setLike] = useState<boolean>(props.markerLike)

    const handleSelector = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLike(!like)
    }

    return (
        <CustomDialog fullWidth={true} maxWidth={"xs"} onClose={() => props.onCancel()} open>
            <div className="content-wrapper">
                <div className="title-wrapper">
                    <Typography>{props.markerText ? '피드백하기(수정)' : '피드백하기'}</Typography>
                    <button onClick={props.onCancel}><img src={BtnX} /></button>
                </div>
                <div className="content-block">
                    <div className="img-wrapper">
                        <img src={props.src} alt="피드백" />
                    </div>
                    <Typography style={{ margin: '15px 0' }} variant="body2">위 부위에 대한 느낌이 어떠셨나요?</Typography>
                    <div className="liker-wrapper">
                        <Selector onClick={(e) => {
                                if(like) return
                                handleSelector(e)
                            }} selected={like} text="좋아요"/>
                        <Selector onClick={(e) => {
                                if(!like) return
                                handleSelector(e)
                            }} selected={!like} text="별로에요"/>
                    </div>
                </div>
                <div>
                    <Typography style={{margin: '15px 0'}} variant="body2">그렇게 느낀 이유가 무엇인가요?</Typography>
                    <TextField
                        className="text-area"
                        id="outlined-multiline-static"
                        multiline
                        minRows={3}
                        variant="outlined"
                        placeholder="이유를 남겨주세요."
                        value={text}
                        onChange={(e) => setText(e.currentTarget.value)}
                    />
                </div>
            </div>
                <div className="confirm-btn-wrapper">
                    <button onClick={() => props.onDelete()}>{props.markerText ? '삭제' : '취소'}</button>
                    <button
                        onClick={() => {
                            if (text.trim() === '') {
                                alert('내용을 입력해주세요.')
                            } else{
                                props.onConfirm(text, like)
                            }
                        }}
                    >저장</button>
                </div>
            {/* <DialogTitle className="dialogTitle">
                통증이 느껴지는 이유
                <IconButton aria-label="close" onClick={() => props.onCancel()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers className="dialogContent">
                <Typography variant="body2">통증이 느껴지는 이유를 작성해주세요.</Typography>
                <QuestionnaireTextField
                    autoFocus
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rowsMax={4}
                    label="이유"
                    value={text}
                    onChange={(e) => setText(e.currentTarget.value)}
                />
            </DialogContent>
            <DialogActions>
                {props.markerText && <Button onClick={() => props.onDelete()}>삭제</Button>}
                <Button onClick={() => props.onCancel()}>취소</Button>
                <Button
                    onClick={() => {
                        if (text === "") {
                            alert("내용을 입력해주세요.")
                        } else {
                            props.onConfirm(text)
                        }
                    }}
                    color="primary"
                >
                    {props.markerText ? "수정" : "완료"}
                </Button>
            </DialogActions> */}
        </CustomDialog>
    )
}
